import React, { useState, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { getFirestore, doc, updateDoc, collection, query, where, getDocs, getDoc, onSnapshot, addDoc, setDoc, Timestamp, arrayUnion, orderBy } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import ReferOutModal from './referOutModal'; // Reuse the modal from BrokerDashAssigned
import ReferralDetailsModal from './referralDetailsModal'; // Reuse Referral Details Modal
import AgentFileUpload from './agentFileUpload'; // Reuse Agent File Upload Modal
import LenderReferralPaymentModal from './lenderReferralPaymentModal';
import Spinner from '../../Spinner';
import './brokerDashAssigned.css'; // Use the same styles for consistency
import './brokerDashLenderReferrals.css';

const BrokerDashLenderReferrals = () => {
  const auth = getAuth();
  const db = getFirestore();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lenderLeads, setLenderLeads] = useState([]);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [currentAgent, setCurrentAgent] = useState({});
  const [modalAgents, setModalAgents] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [referralDetails, setReferralDetails] = useState(null);
  const [isReferralDetailsModalOpen, setIsReferralDetailsModalOpen] = useState(false);
  const [isAgentModalOpen, setIsAgentModalOpen] = useState(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [archiveReason, setArchiveReason] = useState('');
  const [selectedCarrier, setSelectedCarrier] = useState('');
  const [unsuccessfulReason, setUnsuccessfulReason] = useState('');
  const [otherCarrier, setOtherCarrier] = useState('');
  const [agentCarriers, setAgentCarriers] = useState([]);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [collapsedQuotes, setCollapsedQuotes] = useState({});
  const [isSectionCollapsed, setIsSectionCollapsed] = useState(true);





  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      setError('You must be logged in to view your lender leads.');
      setLoading(false);
      return;
    }
  
    let unsubscribe;
  
    const fetchLeads = async () => {
      setLoading(true);
  
      try {
        const userDoc = await getDoc(doc(db, 'userRoles', user.uid));
        if (!userDoc.exists() || !userDoc.data().roles.brokerLogin) {
          setError('You do not have access to this dashboard.');
          await signOut(auth);
          navigate('/');
          return;
        }
  
        const leadsQuery = query(
          collection(db, 'lenderLeads'),
          where('selectedAgent', '==', user.email),
          orderBy('timestamp', 'desc')  // Order by timestamp, newest first

        );
  
        unsubscribe = onSnapshot(
          leadsQuery,
          async (leadsSnapshot) => {
            const leadsList = leadsSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
  
            const referralPromises = leadsList.map(async (lead) => {
              // Fetch the corresponding agentReferrals document
              const referralDocRef = doc(db, 'agentReferrals', lead.id);
              const referralDoc = await getDoc(referralDocRef);
            
              // Fetch the corresponding lenderMyQuotes document using document ID
              const lenderMyQuoteDocRef = doc(db, 'lenderMyQuotes', lead.id);
              const lenderMyQuoteDoc = await getDoc(lenderMyQuoteDocRef);
            
              let status = 'Unverified'; // Default status
              if (lenderMyQuoteDoc.exists()) {
                const lenderMyQuoteData = lenderMyQuoteDoc.data();
                status = lenderMyQuoteData.status || 'Unverified';
              } else {
                console.warn(`No lenderMyQuotes document found for lead ID: ${lead.id}`);
              }
            
              // Add console logs
              console.log('Lead ID:', lead.id);
              console.log('Lead UID:', lead.uid);
              console.log('Fetched status from lenderMyQuotes:', status);
            
              return {
                ...lead,
                referralStatus: referralDoc.exists()
                  ? referralDoc.data()?.quoteInfo?.referralStatus || 'Not Referred'
                  : 'Not Referred',
                closeReason: referralDoc.exists()
                  ? referralDoc.data()?.closeReason || 'pending'
                  : 'pending',
                writtenCarrier: referralDoc.exists()
                  ? referralDoc.data()?.writtenCarrier || 'N/A'
                  : 'N/A',
                unsuccessfulReason: referralDoc.exists()
                  ? referralDoc.data()?.unsuccessfulReason || 'N/A'
                  : 'N/A',
                verificationStatus: status, // Add verification status
              };
            });
            
            
            
  
            const updatedLeadsList = await Promise.all(referralPromises);
            setLenderLeads(updatedLeadsList);
            setLoading(false);

// Initialize collapsedQuotes state
setCollapsedQuotes((prevState) => {
  const newState = { ...prevState };
  updatedLeadsList.forEach((quote) => {
    if (!(quote.id in newState)) {
      newState[quote.id] = true; // Default to collapsed
    }
  });
  return newState;
});
},
(error) => {
console.error('Error fetching leads:', error);
setError('Error fetching leads.');
setLoading(false);
}
);
} catch (err) {
console.error('Error fetching leads:', err);
setError('Error fetching leads.');
setLoading(false);
}
};

  
    fetchLeads();
  
    // Cleanup subscription on unmount
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [auth, db, navigate]);
  
  

  // Function to format the timestamp
const formatTimestamp = (timestamp) => {
  if (!timestamp) return 'N/A';
  let date;

  // Check if timestamp is a Firebase Timestamp object
  if (timestamp.toDate) {
    date = timestamp.toDate();
  } else {
    // If it's a regular Date object or ISO string
    date = new Date(timestamp);
  }

  // Format the date as needed, e.g., 'MM/DD/YYYY, HH:MM AM/PM'
  return date.toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
};




  const handleReferOut = async (quote) => {
    setLoading(true);
    setSelectedQuote(quote);

    try {
      const currentAgentQuery = query(
        collection(db, 'michiganagentlist'),
        where('email', '==', quote.selectedAgent)
      );

      const currentAgentSnapshot = await getDocs(currentAgentQuery);
      if (currentAgentSnapshot.empty) {
        throw new Error("Current agent's data not found in michiganagentlist.");
      }

      const currentAgentDoc = currentAgentSnapshot.docs[0];
      const currentAgentData = currentAgentDoc.data();

      const currentAgentCarrierCodes = currentAgentData.carrierList
        ?.map((carrier) => (typeof carrier === 'string' ? carrier : carrier.carrierCode))
        .filter(Boolean) || [];

      setCurrentAgent({
        ...currentAgentData,
        carrierList: currentAgentCarrierCodes,
        agentId: currentAgentDoc.id,
      });

      const agentsQuery = query(
        collection(db, 'michiganagentlist'),
        where('state', '==', currentAgentData.state)
      );
      const agentsSnapshot = await getDocs(agentsQuery);

      const agentsList = agentsSnapshot.docs
        .map((doc) => {
          const agentData = doc.data();
          const agentIdStr = String(doc.id).trim();
          const agentCarrierCodes = agentData.carrierList
            ?.map((carrier) => (typeof carrier === 'string' ? carrier : carrier.carrierCode))
            .filter(Boolean) || [];
          const differentCarriers = agentCarrierCodes.filter(
            (code) => !currentAgentCarrierCodes.includes(code)
          );
          return { ...agentData, agentId: agentIdStr, differentCarriers };
        })
        .filter((agent) => agent.differentCarriers.length > 0 && agent.email !== currentAgent.email);

      setModalAgents(agentsList);
      setSelectedAgents(agentsList.map((agent) => agent.agentId));
      setIsModalOpen(true);
    } catch (err) {
      console.error('Error in handleReferOut:', err);
      alert(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUploadClick = (quote) => {
    setSelectedQuote(quote);
    setIsAgentModalOpen(true);
  };

  const handleReferralSent = async (quoteId) => {
    try {
      if (!quoteId) throw new Error('Invalid quoteId.');
  
      // Immediately update the referral status for the specific quote in the local state
      setLenderLeads((prevLeads) =>
        prevLeads.map((lead) =>
          lead.id === quoteId ? { ...lead, referralStatus: 'Referral Sent' } : lead
        )
      );
  
      // Fetch the updated referral details for the specific quote from Firestore
      const referralDoc = await getDoc(doc(db, 'agentReferrals', quoteId));
      if (referralDoc.exists()) {
        const referralDetails = referralDoc.data();
        
        // Only update the relevant quote in lenderLeads with new referral data
        setLenderLeads((prevLeads) =>
          prevLeads.map((lead) =>
            lead.id === quoteId
              ? { ...lead, referralStatus: referralDetails.quoteInfo?.referralStatus || 'Referral Sent' }
              : lead
          )
        );
        
        setReferralDetails(referralDetails);
        setIsReferralDetailsModalOpen(true);
      } else {
        console.error('No referral details found for the given quote.');
      }
    } catch (error) {
      console.error('Error in handleReferralSent:', error);
    }
  };

  const openReferralDetailsModal = async (quote) => {
    try {
      const referralDoc = await getDoc(doc(db, 'agentReferrals', quote.id));
      if (referralDoc.exists()) {
        setReferralDetails(referralDoc.data());
      } else {
        setReferralDetails(null);
      }
      setIsReferralDetailsModalOpen(true);
    } catch (error) {
      console.error('Error fetching referral details:', error);
      setReferralDetails(null);
    }
  };
  
  
  const handleArchiveQuote = async (quote) => {
    if (!quote) {
      console.error("Quote is missing or invalid");
      return;
    }
  
    try {
      // Ensure we are querying the agent by the selectedAgent (email) in michiganagentlist
      const agentQuery = query(
        collection(db, 'michiganagentlist'),
        where('email', '==', quote.selectedAgent)
      );
      const agentSnapshot = await getDocs(agentQuery);
  
      if (!agentSnapshot.empty) {
        const agentData = agentSnapshot.docs[0].data();
        setAgentCarriers(agentData.carrierList);  // Update the agent carriers if needed
      }
  
      // Set the selectedQuote to ensure it has a valid quoteId (quote.id)
      setSelectedQuote(quote);
  
      // Open the archive modal
      setIsArchiveModalOpen(true);
    } catch (error) {
      console.error("Error fetching agent's carriers:", error);
    }
  };
  

  const handleArchiveSubmit = async () => {
    if (!selectedQuote) return;
  
    // Create the data object to be updated
    const updateData = {
      brokerArchived: true,
      archiveReason,
    };
  
    // Check if the archive reason is successful and handle the carrier details
    if (archiveReason === 'successful') {
      if (selectedCarrier === 'other' && otherCarrier) {
        updateData.writtenCarrier = otherCarrier; // Add the 'other' carrier
      } else if (selectedCarrier !== 'referral') {
        updateData.writtenCarrier = selectedCarrier; // Add the selected carrier
      }
    }
    // Handle unsuccessful reason
    else if (archiveReason === 'unsuccessful' && unsuccessfulReason) {
      updateData.unsuccessfulReason = unsuccessfulReason; // Add the unsuccessful reason
    }
  
    try {
      // Update both lenderMyQuotes and lenderLeads with the archive details
      const lenderMyQuotesRef = doc(db, 'lenderMyQuotes', selectedQuote.id);
      const lenderLeadsRef = doc(db, 'lenderLeads', selectedQuote.id);
  
      // Perform the updates on both collections
      await Promise.all([
        updateDoc(lenderMyQuotesRef, updateData),
        updateDoc(lenderLeadsRef, updateData)
      ]);
  
      // Remove the archived quote from the current state
      setLenderLeads(prevLeads => prevLeads.filter(lead => lead.id !== selectedQuote.id));
      setIsArchiveModalOpen(false);
  
      // Open the payment modal if the archive reason is valid
      if (archiveReason === 'successful' || (archiveReason === 'unsuccessful' && unsuccessfulReason !== 'could_not_reach' && unsuccessfulReason !== 'not_valid')) {
        setIsPaymentModalOpen(true);
      }
      
      
  
    } catch (error) {
      console.error('Error archiving quote:', error);
    }
  };
  
  

  const handlePaymentSubmit = async (referralAmount, quoteId) => {
    try {
      const user = auth.currentUser;
  
      if (!user) {
        console.error('User is not authenticated');
        return;
      }
  
      // Fetch the quote from lenderLeads using quoteId
      const lenderLeadRef = doc(db, 'lenderLeads', quoteId);
      const lenderLeadDoc = await getDoc(lenderLeadRef);
  
      if (!lenderLeadDoc.exists()) {
        console.error(`No lender lead found for quoteId: ${quoteId}`);
        return;
      }
  
      const lenderLeadData = lenderLeadDoc.data();
  
      // Extract relevant details from lenderLeadData
      const referringLenderEmail = lenderLeadData.lenderEmail || 'N/A';
      const clientFirstName = lenderLeadData.homeowner1?.firstName || 'N/A';
      const clientLastName = lenderLeadData.homeowner1?.lastName || 'N/A';
      const clientName = `${clientFirstName} ${clientLastName}`;
  
      // Update agentPayments document
      const agentPaymentsRef = doc(db, 'agentPayments', user.uid);
      const agentPaymentsDoc = await getDoc(agentPaymentsRef);
  
      let currentMonthOwed = agentPaymentsDoc.exists()
        ? agentPaymentsDoc.data().currentMonthOwed || 0
        : 0;
  
      const newTotalOwed = currentMonthOwed + referralAmount;
  
      // Prepare the payment entry
      const paymentEntry = {
        referralAmount: referralAmount,
        referringAgent: {
          name: 'Lender',  // Set to 'Lender' for lender referrals
          email: referringLenderEmail, // Email of the lender
        },
        clientName: clientName, // Constructed from homeowner1's first and last name
        dateReferred: Timestamp.now(),
      };
  
      // Update the agentPayments document
      await updateDoc(agentPaymentsRef, {
        currentMonthOwed: newTotalOwed,
        currentMonthPayments: arrayUnion(paymentEntry),
      });
  
      // Update the paymentStatus in lenderMyQuotes document to 'Submitted' and add the submittedPayment amount
      const lenderMyQuoteDocRef = doc(db, 'lenderMyQuotes', quoteId);
      await updateDoc(lenderMyQuoteDocRef, {
        paymentStatus: 'Submitted',
        submittedPayment: referralAmount // Add submittedPayment field
      });
  
      console.log('Payment submitted, paymentStatus updated to "Submitted", and submittedPayment written.');
    } catch (error) {
      console.error('Error submitting payment for lender lead:', error);
    }
  };
  
  
  const renderAgentAttachedFiles = (files) => {
    if (!files || files.length === 0) {
      return 'None';
    }
  
    return (
      <ul>
        {files.map((fileItem, index) => {
          if (typeof fileItem === 'string') {
            // If fileItem is a string, it's a URL
            const name = getFileNameFromURL(fileItem);
            return (
              <li key={index}>
                <a href={fileItem} target="_blank" rel="noopener noreferrer">
                  {name || `File ${index + 1}`}
                </a>
              </li>
            );
          } else {
            // fileItem is an object with 'url' and 'name'
            return (
              <li key={index}>
                <a href={fileItem.url} target="_blank" rel="noopener noreferrer">
                  {fileItem.name || `File ${index + 1}`}
                </a>
              </li>
            );
          }
        })}
      </ul>
    );
  };
  
  // Helper function to extract file name from URL
  const getFileNameFromURL = (url) => {
    try {
      const decodedURL = decodeURIComponent(url);
      const parts = decodedURL.split('/');
      const lastPart = parts[parts.length - 1];
      const [nameWithParams] = lastPart.split('?'); // Remove any query parameters
      return nameWithParams;
    } catch (error) {
      console.error('Error extracting file name from URL:', error);
      return 'Unknown File';
    }
  };
  
  

  const renderReferralStatus = (quote) => {
    return quote.referralStatus || 'Not Referred';
  };

  const renderCloseDetails = (lead) => {
    if (lead.closeReason === 'pending') {
      return 'Pending';
    }
    if (lead.closeReason === 'successful') {
      return `Successful with ${lead.writtenCarrier || 'N/A'}`;
    }
    if (lead.closeReason === 'unsuccessful') {
      return `Unsuccessful: ${lead.unsuccessfulReason || 'N/A'}`;
    }
    return 'N/A';
  };

  const toggleQuoteCollapse = (quoteId) => {
    setCollapsedQuotes((prevState) => ({
      ...prevState,
      [quoteId]: !prevState[quoteId],
    }));
  };

  const collapseAllQuotes = () => {
    const allCollapsedState = {};
    lenderLeads.forEach((quote) => {
      allCollapsedState[quote.id] = true;
    });
    setCollapsedQuotes(allCollapsedState);
  };
  
  const uncollapseAllQuotes = () => {
    const allUncollapsedState = {};
    lenderLeads.forEach((quote) => {
      allUncollapsedState[quote.id] = false;
    });
    setCollapsedQuotes(allUncollapsedState);
  };


  const handleVerifyContact = async (lead) => {
    try {
      // Get the reference to the lenderMyQuotes document using lead.id
      const lenderMyQuoteDocRef = doc(db, 'lenderMyQuotes', lead.id);
      const lenderMyQuoteDoc = await getDoc(lenderMyQuoteDocRef);
  
      if (lenderMyQuoteDoc.exists()) {
        // Update both the status to "Verified" and the paymentStatus to "Eligible"
        await updateDoc(lenderMyQuoteDocRef, { 
          status: 'Verified', 
          paymentStatus: 'Eligible' 
        });
  
        // Update the local state to set verificationStatus to 'Verified' and paymentStatus to 'Eligible'
        setLenderLeads((prevLeads) =>
          prevLeads.map((prevLead) => {
            const prevLeadId = String(prevLead.id);
            const leadId = String(lead.id);
  
            if (prevLeadId === leadId) {
              return { 
                ...prevLead, 
                verificationStatus: 'Verified', 
                paymentStatus: 'Eligible' // Add paymentStatus to local state
              };
            }
            return prevLead;
          })
        );
  
        alert('Contact verified successfully, and payment status updated to Eligible.');
      } else {
        alert('Corresponding lenderMyQuotes document not found.');
      }
    } catch (error) {
      console.error('Error verifying contact and updating payment status:', error);
      alert('Error verifying contact.');
    }
  };

  const filterQuotes = (quote) => {
    const name = quote.homeowner1?.firstName?.toLowerCase() || '';
    const email = quote.homeowner1?.email?.toLowerCase() || '';
    const lenderEmail = quote.lenderEmail?.toLowerCase() || '';
    const search = filterText.toLowerCase();
  
    // Check if the search text matches Homeowner 1's name, email, or lender's email
    return name.includes(search) || email.includes(search) || lenderEmail.includes(search);
  };
  
  
  
  // Helper function to check if status is 'Verified'
const isVerified = (status) => {
  return status && status.toString().trim().toLowerCase() === 'verified';
};
  
  
const renderQuoteDetails = (quote) => {
  // Helper function to format the timestamp properly
  const formatTimestamp = (timestamp) => {
    if (timestamp && typeof timestamp.toDate === 'function') {
      return timestamp.toDate().toLocaleString();  // Firestore Timestamp object
    } else if (timestamp && timestamp.seconds) {
      // Manually construct a Date object if timestamp is a Firestore-like object
      return new Date(timestamp.seconds * 1000).toLocaleString();
    }
    return 'N/A';  // Fallback if timestamp is not available
  };




  return (
    <div className="quote-details">
      <div className='quote-section'>
      <h3 className='section-title'>Submission Details</h3>
      <p><strong>Submitted At:</strong> {formatTimestamp(quote.timestamp)}</p>
      <p><strong>Lender Email:</strong> {quote.lenderEmail || 'N/A'}</p> {/* Add lenderEmail */}
      </div>
      <div className='quote-section'>
        <h3 className='section-title'>Homeowner 1 Information</h3>
      <p><strong>Homeowner 1:</strong> {`${quote.homeowner1.firstName} ${quote.homeowner1.lastName}`} | {quote.homeowner1.email}</p>

      <p><strong>Birthdate 1:</strong> {quote.homeowner1.birthdate || 'N/A'}</p>
      <p><strong>Phone 1:</strong> {quote.homeowner1.phone || 'N/A'}</p>
      </div>
      <div className='quote-section'>
        <h3 className='section-title'>Homeowner 2 Information</h3>
      <p><strong>Homeowner 2:</strong> {`${quote.homeowner2.firstName} ${quote.homeowner2.lastName}`} | {quote.homeowner2.email}</p>
      <p><strong>Birthdate 2:</strong> {quote.homeowner2.birthdate || 'N/A'}</p>
      <p><strong>Phone 2:</strong> {quote.homeowner2.phone || 'N/A'}</p>
      </div>
      <div className='quote-section'>
        <h3 className='section-title'>Address Information</h3>
      <p><strong>Current Address:</strong> {`${quote.currentAddress.street}, ${quote.currentAddress.city}, ${quote.currentAddress.state}, ${quote.currentAddress.zip}`}</p>
      <p><strong>New Address:</strong> {`${quote.newAddress.street}, ${quote.newAddress.city}, ${quote.newAddress.state}, ${quote.newAddress.zip}`}</p>
      </div>
      <div className='quote-section'>
        <h3 className='section-title'>Additional Information</h3>
      <p><strong>Notes:</strong> {quote.notes}</p>
      <p><strong>Attached Files:</strong> {quote.attachedFiles ? quote.attachedFiles.map((file, index) => <a key={index} href={file} target="_blank" rel="noopener noreferrer">{`File ${index + 1}`}</a>) : 'None'}</p>
      <p><strong>Agent Attached Files:</strong> {renderAgentAttachedFiles(quote.agentAttachedFiles)}</p>
      </div>
      <div className='quote-section'>
        <h3 className='section-title'>Status</h3>
      <p><strong>Referral Status:</strong> {renderReferralStatus(quote)}</p>
      <p><strong>Close Reason:</strong> {renderCloseDetails(quote)}</p>
      </div>
    </div>
  );
};


return (


  <div className='broker-dash-lender-referrals'>
    {/* Section Header with Collapse Control */}
    <div 
      className="section-header" 
      onClick={() => setIsSectionCollapsed(prev => !prev)} 
      aria-label={isSectionCollapsed ? 'Expand Lender Leads' : 'Collapse Lender Leads'}
      style={{ cursor: 'pointer' }} 
    
    >
  <span className="section-toggle-symbol">
    {isSectionCollapsed ? '+' : '-'}
  </span>
      <h2 className='broker-dash-title'>Your Lender Leads</h2>
      {isSectionCollapsed && (
        <p className="item-count">
          ({
            lenderLeads
              .filter((quote) => !quote.brokerArchived)
              .filter(filterQuotes).length
          } items)
        </p>
      )}
    </div>

    {/* Render content conditionally based on isSectionCollapsed */}
    {!isSectionCollapsed && (
      <>
        {/* Collapse Controls */}
        <div className="collapse-controls">
          <button onClick={collapseAllQuotes} className="collapse-all-button">
            Collapse All
          </button>
          <button onClick={uncollapseAllQuotes} className="uncollapse-all-button">
            Uncollapse All
          </button>
        </div>

        {/* Filter Input */}
        <div className='filter-container'>
          <input
            type="text"
            placeholder="Search by Homeowner 1 Name, Email or Lender Email"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            className="filter-input"
          />
        </div>

        {/* Loading Spinner */}
        {loading && (
          <div className="spinner-overlay">
            <Spinner />
          </div>
        )}
        {/* Error Message */}
        {error && <p className="error-message">{error}</p>}

        {/* Leads List */}
        {lenderLeads.length > 0 ? (
          <ul className="leads-list">
            {lenderLeads
              .filter((quote) => !quote.brokerArchived) // Exclude archived quotes
              .filter(filterQuotes) // Apply filtering by name or email
              .map((quote) => (
                <li key={quote.id} className="lead-item"
                onClick={() => toggleQuoteCollapse(quote.id)} // Make the entire quote item clickable
                style={{ cursor: 'pointer' }} // Change cursor to indicate clickability
                >
                  {/* Quote Header */}
                  <div className="quote-header">
                  <span className="toggle-symbol">
      {collapsedQuotes[quote.id] ? '+' : '-'}
    </span>
                    {/* Collapsed Info */}
                    <div className="collapsed-info">
                      <p>
                        <strong>Submitted At:</strong> {formatTimestamp(quote.timestamp)}
                      </p>
                      <p>
                        <strong>Lender Email:</strong> {quote.lenderEmail || 'N/A'}
                      </p>
                      <p>
                        <strong>Homeowner 1 Name:</strong>{' '}
                        {`${quote.homeowner1.firstName} ${quote.homeowner1.lastName}`}
                      </p>
                    </div>
                  </div>

                  {/* Full Details */}
                  {!collapsedQuotes[quote.id] && (
                    <>
                      {renderQuoteDetails(quote)}
                      <div className="lead-actions">
                      <button
    className="action-button"
    onClick={(e) => {
      e.stopPropagation(); // Prevent collapsing on button click
      handleReferOut(quote);
    }}
    disabled={loading}
  >
                          Refer Out
                        </button>
                        <button
    className="action-button"
    onClick={(e) => {
      e.stopPropagation();
      openReferralDetailsModal(quote);
    }}
  >
                          Referral Details
                        </button>
                        <button
    className="action-button"
    onClick={(e) => {
      e.stopPropagation();
      handleUploadClick(quote);
    }}
  >
                          Upload Files
                        </button>
                        <button
    className="action-button"
    onClick={(e) => {
      e.stopPropagation();
      handleArchiveQuote(quote);
    }}
  >
                          Archive
                        </button>
                        <button
    className="action-button"
    onClick={(e) => {
      e.stopPropagation();
      handleVerifyContact(quote);
    }}
    disabled={isVerified(quote.verificationStatus)}
  >
                          Verify Contact
                        </button>
                      </div>
                    </>
                  )}
                </li>
              ))}
          </ul>
        ) : (
          <p className='no-leads-message'>No lender leads found.</p>
        )}

        {/* Modals and other components */}
        {isModalOpen && selectedQuote && currentAgent && (
          <ReferOutModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            agents={modalAgents}
            currentAgent={currentAgent}
            selectedQuote={selectedQuote}
            onSendReferral={handleReferralSent}
            setIsModalOpen={setIsModalOpen}
          />
        )}

        {isReferralDetailsModalOpen && (
          <ReferralDetailsModal
            isOpen={isReferralDetailsModalOpen}
            onClose={() => setIsReferralDetailsModalOpen(false)}
            referralDetails={referralDetails}
          />
        )}

        {isAgentModalOpen && (
          <AgentFileUpload
            quote={selectedQuote}
            onUploadComplete={() => setIsAgentModalOpen(false)}
            onClose={() => setIsAgentModalOpen(false)}
            collectionName="lenderLeads"
          />
        )}

        {/* Payment Modal */}
        {isPaymentModalOpen && (
          <LenderReferralPaymentModal
            isOpen={isPaymentModalOpen}
            onClose={() => setIsPaymentModalOpen(false)}
            onSubmit={handlePaymentSubmit}
            quoteId={selectedQuote?.id} // Ensure the correct quoteId is passed here
          />
        )}

        {/* Archive Modal */}
        {isArchiveModalOpen && (
          <div className="broker-dash-lender-archive-modal">
            <h3 className='broker-dash-lender-archive-modal-header'>Archive Quote</h3>
            <label className='broker-dash-lender-archive-modal-reason'>
              Archive Reason:
              <select value={archiveReason} onChange={(e) => setArchiveReason(e.target.value)}>
                <option value="">Select a reason</option>
                <option value="successful">Archive Successful</option>
                <option value="unsuccessful">Archive Unsuccessful</option>
              </select>
            </label>

            {archiveReason === 'successful' && (
              <>
                <label>
                  Select Option:
                  <select
                    value={selectedCarrier}
                    onChange={(e) => setSelectedCarrier(e.target.value)}
                  >
                    <option value="">Select an option</option>
                    <option value="referral">Referral</option>
                    {agentCarriers.map((carrier, index) => (
                      <option key={index} value={carrier.carrierCode}>
                        {carrier.carrierName}
                      </option>
                    ))}
                    <option value="other">Other</option>
                  </select>
                </label>
                {selectedCarrier === 'other' && (
                  <input
                    type="text"
                    placeholder="Enter other carrier"
                    value={otherCarrier}
                    onChange={(e) => setOtherCarrier(e.target.value)}
                  />
                )}
              </>
            )}

            {archiveReason === 'unsuccessful' && (
              <label>
                Unsuccessful Reason:
                <select
                  value={unsuccessfulReason}
                  onChange={(e) => setUnsuccessfulReason(e.target.value)}
                >
                  <option value="">Select a reason</option>
                  <option value="could_not_reach">Couldn't reach</option>
                  <option value="not_valid">Not valid</option>
                  <option value="not_competitive">Not competitive</option>
                </select>
              </label>
            )}

            <button onClick={handleArchiveSubmit}>Submit</button>
            <button onClick={() => setIsArchiveModalOpen(false)}>Cancel</button>
          </div>
        )}
      </>
    )}
  </div>
);

};

export default BrokerDashLenderReferrals;
