// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCb09Hi1Nx0G1kgGisi-KRLvxYS3A0R8M0",
  authDomain: "abc-quote.firebaseapp.com",
  projectId: "abc-quote",
  storageBucket: "abc-quote.appspot.com",
  messagingSenderId: "753070875309",
  appId: "1:753070875309:web:3202bcfdb380dada865c24",
  measurementId: "G-7J4B6BKTH0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);
const storage = getStorage(app);

export { app, db, auth, analytics, functions, storage };
