import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebaseConfig';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import './forgotPasswordModal.css';

const db = getFirestore();

const ForgotPasswordModal = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userDoc = await getDoc(doc(db, 'userRoles', email));
      if (userDoc.exists()) {
        await sendPasswordResetEmail(auth, email);
        setMessage('Password reset email sent. Please check your inbox.');
      } else {
        setMessage('You do not have access to reset the password.');
      }
    } catch (error) {
      setMessage('Error sending password reset email. Please try again.');
    }
  };

  return (
    <div className="forgot-password-modal">
      <button className="forgot-password-close-button" onClick={onClose}>X</button>
      <h2>Forgot Password</h2>
      <form onSubmit={handleSubmit}>
        <label>Email Address</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button className='send-reset-link' type="submit">Send Reset Link</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ForgotPasswordModal;


