import React, { useState, useEffect, useRef } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage, auth } from '../../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import AuthenticationModal from '../../Quote Info/authenticationModal';
import Login from '../../Quote Info/login';

function LenderQuoteFileUpload({ onUpload, selectedAgentEmail }) {
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState({});
  const [fileUrls, setFileUrls] = useState([]);  // Add state to store file URLs
  const [error, setError] = useState('');
  const [dragOver, setDragOver] = useState(false);
  const [user, setUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const newFiles = selectedFiles.filter((file) => isValidFile(file));
    setFiles([...files, ...newFiles]);
    e.target.value = null; // Reset the file input
  };

  const isValidFile = (file) => {
    const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    return allowedTypes.includes(file.type);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    const newFiles = droppedFiles.filter((file) => isValidFile(file));
    setFiles([...files, ...newFiles]);
  };

  const handleUpload = async () => {
    if (!user) {
      setIsModalOpen(true);
      return;
    }

    setUploadLoading(true);
    setUploadSuccess(false);

    const newProgress = {};
    const uploadedFileUrls = [];

    try {
      const promises = files.map((file) => {
        return new Promise((resolve, reject) => {
          const storageRef = ref(storage, `documents/${user.uid}/${file.name}`);
          const uploadTask = uploadBytesResumable(storageRef, file);

          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progressPercent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              newProgress[file.name] = progressPercent;
              setProgress({ ...newProgress });
            },
            (error) => {
              console.error('Upload failed', error);
              setError('Upload failed');
              reject(error);
            },
            async () => {
              try {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                uploadedFileUrls.push(downloadURL);  // Add the file URL to the array

                // Call Firebase Function to send email notification
                const response = await fetch('https://us-central1-abc-quote.cloudfunctions.net/sendLenderFileUploadEmail', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    customerId: user.uid,
                    customerEmail: user.email,
                    fileURL: downloadURL,
                    fileName: file.name,
                    agentEmail: selectedAgentEmail, // Pass the selected agent's email
                  })
                });

                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }

                resolve();
              } catch (error) {
                console.error('Error getting download URL or sending email', error);
                setError('Error processing file upload');
                reject(error);
              }
            }
          );
        });
      });

      await Promise.all(promises);
      setFileUrls(uploadedFileUrls);  // Store the file URLs
      setFiles([]);  // Clear files after successful upload
      setError('');
      setUploadLoading(false);
      setUploadSuccess(true);

      onUpload(uploadedFileUrls);  // Pass file URLs back

    } catch (error) {
      console.error('Error uploading files', error);
      setError('Error uploading files');
      setUploadLoading(false);
    }
  };

  const handleRemove = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const handleCustomButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <div className="drag-drop-box-header">
        <button id="drag-drop-box-sign-in" onClick={handleUpload}>Log In or Sign Up</button>
        {user && <button className="authButton" id="uploadButtonLogout" onClick={() => auth.signOut()}>Logout</button>}
      </div>
      <div
        className={`file-upload-container ${dragOver ? 'drag-over' : ''}`}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          type="file"
          onChange={handleFileChange}
          multiple
          ref={fileInputRef} // Attach the ref to the file input
          style={{ display: 'none' }} // Hide the default file input
        />
        <button type='button' id="drag-drop-box-choose-files" onClick={handleCustomButtonClick}>Choose Files</button>
        <button type='button' id="drag-drop-box-upload" onClick={handleUpload}>Upload</button>
        <ul id="drag-drop-box-attached">
          {files.map((file, index) => (
            <li key={index}>
              {file.name}
              <div>
                {progress[file.name] !== undefined ? (
                  <span>Progress: {progress[file.name].toFixed(2)}%</span>
                ) : (
                  <span style={{ fontWeight: 'bold' }}>Click the "Upload" button to complete...</span>
                )}
                {progress[file.name] === 100 && <span> - Upload Complete</span>}
              </div>
              <button onClick={() => handleRemove(index)}>Remove</button>
            </li>
          ))}
        </ul>
        {uploadLoading && <div>Uploading...</div>}
        {uploadSuccess && <div>Upload successful!</div>}
        {error && <div>{error}</div>}
        <AuthenticationModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <Login />
        </AuthenticationModal>
      </div>
    </>
  );
}

export default LenderQuoteFileUpload;






