// BrokerDashReferredQuotes.js

import React, { useState, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import {
  getFirestore,
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  arrayUnion,
  Timestamp,
  setDoc,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import PaymentCalculationModal from './paymentCalculationModal';
import './brokerDashReferredQuotes.css';

const BrokerDashReferredQuotes = () => {
  const auth = getAuth();
  const db = getFirestore();
  const navigate = useNavigate();

  const [referredQuotes, setReferredQuotes] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [closeReason, setCloseReason] = useState('');
  const [selectedCarrier, setSelectedCarrier] = useState('');
  const [unsuccessfulReason, setUnsuccessfulReason] = useState('');
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [annualPremium, setAnnualPremium] = useState(0);
  const [filterText, setFilterText] = useState('');
  const [collapsedQuotes, setCollapsedQuotes] = useState({});
  const [isSectionCollapsed, setIsSectionCollapsed] = useState(true);


  useEffect(() => {
    const fetchAgentData = async () => {
      setLoading(true);
      const user = auth.currentUser;
      if (!user) {
        setError('You must be logged in to view your quotes.');
        setLoading(false);
        return;
      }

      try {
        const userDoc = await getDoc(doc(db, 'userRoles', user.uid));
        if (!userDoc.exists() || !userDoc.data().roles.brokerLogin) {
          setError('You do not have access to this dashboard.');
          await signOut(auth);
          navigate('/');
          return;
        }

        const referredQuotesQuery = query(
          collection(db, 'agentReferrals'),
          where('agentEmail', '==', user.email),
          where('quoteInfo.referralStatus', 'in', ['Referral Sent', 'accepted'])
        );
        const referredQuotesSnapshot = await getDocs(referredQuotesQuery);

        const referredQuotesList = referredQuotesSnapshot.docs.map((doc) => ({
          id: doc.id,
          referralStatus: doc.data().quoteInfo?.referralStatus || 'Not Referred',
          ...doc.data(),
        }));

        setReferredQuotes(referredQuotesList);

                // Initialize collapsedQuotes state for new quotes only
                setCollapsedQuotes((prevState) => {
                  const newState = { ...prevState };
                  referredQuotesList.forEach((quote) => {
                    if (!(quote.id in newState)) {
                      newState[quote.id] = true; // Default to collapsed
                    }
                  });
                  return newState;
                });

      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Error fetching data.');
      } finally {
        setLoading(false);
      }
    };

    fetchAgentData();
  }, [navigate, auth, db]);



    // Function to toggle individual quote collapse
    const toggleQuoteCollapse = (quoteId) => {
      setCollapsedQuotes((prevState) => ({
        ...prevState,
        [quoteId]: !prevState[quoteId],
      }));
    };
  
    // Function to collapse all quotes
    const collapseAllQuotes = () => {
      const allCollapsedState = {};
      referredQuotes.forEach((quote) => {
        allCollapsedState[quote.id] = true;
      });
      setCollapsedQuotes(allCollapsedState);
    };
  
    // Function to uncollapse all quotes
    const uncollapseAllQuotes = () => {
      const allUncollapsedState = {};
      referredQuotes.forEach((quote) => {
        allUncollapsedState[quote.id] = false;
      });
      setCollapsedQuotes(allUncollapsedState);
    };




  const handleAcceptQuote = async (quoteId) => {
    const quoteRef = doc(db, 'agentReferrals', quoteId);
    await updateDoc(quoteRef, {
      'quoteInfo.referralStatus': 'accepted',
    });

    setReferredQuotes((prevQuotes) =>
      prevQuotes.map((quote) =>
        quote.id === quoteId
          ? { ...quote, quoteInfo: { ...quote.quoteInfo, referralStatus: 'accepted' } }
          : quote
      )
    );
  };

  const handleCloseQuoteClick = (quote) => {
    setSelectedQuote(quote);
    setShowCloseModal(true);
  };

  const handleCloseQuoteSubmit = async () => {
    if (!selectedQuote) {
      console.error('No quote selected');
      return;
    }

    if (!closeReason) {
      alert('Please select a close reason before submitting.');
      return;
    }

    if (closeReason === 'unsuccessful' && unsuccessfulReason) {
      try {
        const updateData = {
          'quoteInfo.referralStatus': 'closed',
          closeReason,
          unsuccessfulReason,
        };

        const quoteRef = doc(db, 'agentReferrals', selectedQuote.id);
        await updateDoc(quoteRef, updateData);

        setReferredQuotes((prevQuotes) =>
          prevQuotes.filter((quote) => quote.id !== selectedQuote.id)
        );

        setSelectedQuote(null);
        setShowCloseModal(false);
      } catch (error) {
        console.error('Error closing quote:', error);
      }
      return;
    }

    if (closeReason === 'successful') {
      setShowCloseModal(false);
      setShowPaymentModal(true);
    }
  };

  const handlePaymentModalSubmit = async (referralAmount) => {
    if (!selectedQuote?.id) {
      console.error('Selected quote ID is missing');
      return;
    }

    try {
      await handlePaymentSubmit(referralAmount, selectedQuote.id);

      const updateData = {
        'quoteInfo.referralStatus': 'closed',
        closeReason: 'successful',
        writtenCarrier: selectedCarrier,
      };

      const quoteRef = doc(db, 'agentReferrals', selectedQuote.id);
      await updateDoc(quoteRef, updateData);

      setReferredQuotes((prevQuotes) =>
        prevQuotes.filter((quote) => quote.id !== selectedQuote.id)
      );

      setSelectedQuote(null);
      setShowPaymentModal(false);
      setShowCloseModal(false);
    } catch (error) {
      console.error('Error closing quote after payment:', error);
    }
  };

  const filteredQuotes = referredQuotes.filter((quote) => {
    const searchTerm = filterText.toLowerCase();
  
    const homeowner1Match =
      `${quote.quoteInfo?.homeowner1?.firstName || ''} ${quote.quoteInfo?.homeowner1?.lastName || ''}`.toLowerCase().includes(searchTerm) ||
      (quote.quoteInfo?.homeowner1?.email || '').toLowerCase().includes(searchTerm);
  
    const quoteMatch =
      (quote.quoteInfo?.name || '').toLowerCase().includes(searchTerm) ||
      (quote.quoteInfo?.email || '').toLowerCase().includes(searchTerm);
  
    const referringAgentMatch =
      (quote.currentAgent?.name || '').toLowerCase().includes(searchTerm) ||
      (quote.currentAgent?.email || '').toLowerCase().includes(searchTerm);
  
    return homeowner1Match || quoteMatch || referringAgentMatch;
  });


  const handlePaymentSubmit = async (referralAmount, quoteId) => {
    try {
      const user = auth.currentUser;

      if (!user) {
        console.error('User is not authenticated');
        return;
      }

      const userRolesRef = doc(db, 'userRoles', user.uid);
      const userRolesDoc = await getDoc(userRolesRef);
      if (!userRolesDoc.exists()) {
        console.error('User roles document not found.');
        return;
      }

      const userRoles = userRolesDoc.data();
      if (!userRoles.roles?.brokerLogin) {
        console.error('User does not have brokerLogin role.');
        return;
      }

      if (!quoteId) {
        console.error('quoteId is undefined or invalid:', quoteId);
        return;
      }

      const quoteRef = doc(db, 'agentReferrals', quoteId);
      const quoteDoc = await getDoc(quoteRef);

      if (!quoteDoc.exists()) {
        console.error('Quote not found for ID:', quoteId);
        return;
      }

      const quoteData = quoteDoc.data();

      const referringAgentId = quoteData.currentAgent?.agentId;
      const referringAgentName = quoteData.currentAgent?.name || 'N/A';
      const referringAgentEmail = quoteData.currentAgent?.email || 'N/A';
      const clientName = quoteData.quoteInfo?.name || 'N/A';
      const dateReferred = quoteData.quoteInfo?.dateReferred || new Date();

      if (!referringAgentId) {
        console.error('Referring agent ID is missing.');
        return;
      }

      // Update agentPayments for the quoting agent
      const agentPaymentsRef = doc(db, 'agentPayments', user.uid);
      const agentPaymentsDoc = await getDoc(agentPaymentsRef);

      let currentMonthOwed = agentPaymentsDoc.exists()
        ? agentPaymentsDoc.data().currentMonthOwed || 0
        : 0;

      if (!agentPaymentsDoc.exists()) {
        await setDoc(agentPaymentsRef, {
          currentMonthOwed: 0,
          currentMonthPayments: [],
          monthlyPaymentsArchive: {},
        });
      }

      const newTotalOwed = currentMonthOwed + referralAmount;

      const paymentEntry = {
        referralAmount: referralAmount,
        referringAgent: {
          name: referringAgentName,
          email: referringAgentEmail,
        },
        clientName: clientName,
        dateReferred:
          dateReferred instanceof Date ? Timestamp.fromDate(dateReferred) : dateReferred,
      };

      await updateDoc(agentPaymentsRef, {
        currentMonthOwed: newTotalOwed,
        currentMonthPayments: arrayUnion(paymentEntry),
      });

      // Update agentIncome for the referring agent
      const agentIncomeRef = doc(db, 'agentIncome', referringAgentId);
      const agentIncomeDoc = await getDoc(agentIncomeRef);

      let currentMonthIncome = agentIncomeDoc.exists()
        ? agentIncomeDoc.data().currentMonthIncome || 0
        : 0;
      let totalIncomeEver = agentIncomeDoc.exists()
        ? agentIncomeDoc.data().totalIncomeEver || 0
        : 0;

      if (!agentIncomeDoc.exists()) {
        await setDoc(agentIncomeRef, {
          currentMonthIncome: 0,
          currentMonthPayments: [],
          totalIncomeEver: 0,
          monthlyIncomeArchive: {},
        });
      }

      const newTotalIncome = currentMonthIncome + referralAmount;
      const newTotalIncomeEver = totalIncomeEver + referralAmount;

      await updateDoc(agentIncomeRef, {
        currentMonthIncome: newTotalIncome,
        totalIncomeEver: newTotalIncomeEver,
        currentMonthPayments: arrayUnion(paymentEntry),
      });
    } catch (error) {
      console.error('Error updating payment in Firestore:', error);
    }
  };

  const renderAgentAttachedFiles = (files) => {
    if (!files || files.length === 0) {
      return 'None';
    }


    

    return (
      <ul>
        {files.map((fileItem, index) => {
          if (typeof fileItem === 'string') {
            // If fileItem is a string, it's a URL
            const name = getFileNameFromURL(fileItem);
            return (
              <li key={index}>
                <a href={fileItem} target="_blank" rel="noopener noreferrer">
                  {name || `File ${index + 1}`}
                </a>
              </li>
            );
          } else {
            // fileItem is an object with 'url' and 'name'
            return (
              <li key={index}>
                <a href={fileItem.url} target="_blank" rel="noopener noreferrer">
                  {fileItem.name || `File ${index + 1}`}
                </a>
              </li>
            );
          }
        })}
      </ul>
    );
  };

  // Helper function to extract file name from URL
  const getFileNameFromURL = (url) => {
    try {
      const decodedURL = decodeURIComponent(url);
      const parts = decodedURL.split('/');
      const lastPart = parts[parts.length - 1];
      const [nameWithParams] = lastPart.split('?'); // Remove any query parameters
      return nameWithParams;
    } catch (error) {
      console.error('Error extracting file name from URL:', error);
      return 'Unknown File';
    }
  };

  const standardizeQuoteInfo = (quoteInfo) => {
    return {
      // For homeowner1
      homeowner1FirstName:
        quoteInfo.homeowner1FirstName ||
        quoteInfo.homeowner1?.firstName ||
        'N/A',
      homeowner1LastName:
        quoteInfo.homeowner1LastName ||
        quoteInfo.homeowner1?.lastName ||
        'N/A',
      homeowner1Email:
        quoteInfo.homeowner1Email ||
        quoteInfo.homeowner1?.email ||
        'N/A',
      homeowner1Phone:
        quoteInfo.homeowner1Phone ||
        quoteInfo.homeowner1?.phone ||
        'N/A',
      homeowner1Birthdate:
        quoteInfo.homeowner1Birthdate ||
        quoteInfo.homeowner1?.birthdate ||
        'N/A',
      // For homeowner2
      homeowner2FirstName:
        quoteInfo.homeowner2FirstName ||
        quoteInfo.homeowner2?.firstName ||
        'N/A',
      homeowner2LastName:
        quoteInfo.homeowner2LastName ||
        quoteInfo.homeowner2?.lastName ||
        'N/A',
      homeowner2Email:
        quoteInfo.homeowner2Email ||
        quoteInfo.homeowner2?.email ||
        'N/A',
      homeowner2Phone:
        quoteInfo.homeowner2Phone ||
        quoteInfo.homeowner2?.phone ||
        'N/A',
      homeowner2Birthdate:
        quoteInfo.homeowner2Birthdate ||
        quoteInfo.homeowner2?.birthdate ||
        'N/A',
      // For currentAddress
      currentAddressStreet:
        quoteInfo.currentAddressStreet ||
        quoteInfo.currentAddress?.street ||
        'N/A',
      currentAddressCity:
        quoteInfo.currentAddressCity ||
        quoteInfo.currentAddress?.city ||
        'N/A',
      currentAddressState:
        quoteInfo.currentAddressState ||
        quoteInfo.currentAddress?.state ||
        'N/A',
      currentAddressZip:
        quoteInfo.currentAddressZip ||
        quoteInfo.currentAddress?.zip ||
        'N/A',
      // For newAddress
      newAddressStreet:
        quoteInfo.newAddressStreet ||
        quoteInfo.newAddress?.street ||
        'N/A',
      newAddressCity:
        quoteInfo.newAddressCity ||
        quoteInfo.newAddress?.city ||
        'N/A',
      newAddressState:
        quoteInfo.newAddressState ||
        quoteInfo.newAddress?.state ||
        'N/A',
      newAddressZip:
        quoteInfo.newAddressZip ||
        quoteInfo.newAddress?.zip ||
        'N/A',
      // Other fields
      notes: quoteInfo.notes || quoteInfo.additionalNotes || 'N/A',
      attachedFiles: quoteInfo.attachedFiles || [],
      agentAttachedFiles: quoteInfo.agentAttachedFiles || [],
      formType: quoteInfo.formType || (quoteInfo.homeowner1 ? 'Lender Lead' : 'Standard Referral'),
    };
  };

  return (
    <div className="broker-dash-referred-quotes">
      <div 
        className="section-header" 
        onClick={() => setIsSectionCollapsed(prev => !prev)} 
        aria-label={isSectionCollapsed ? 'Expand Lender Leads' : 'Collapse Lender Leads'}
        style={{ cursor: 'pointer' }}      
      >
  <span className="section-toggle-symbol">
    {isSectionCollapsed ? '+' : '-'}
  </span>
        <h2 className="referred-title">Referred Quotes</h2>
        {isSectionCollapsed && (
          <p className="referred-item-count">({filteredQuotes.length} items)</p>
        )}
      </div>
      {!isSectionCollapsed && (
        <>
          {/* Collapse Controls */}
          <div className="referred-collapse-controls">
            <button onClick={collapseAllQuotes} className="referred-collapse-all-button">
              Collapse All
            </button>
            <button onClick={uncollapseAllQuotes} className="referred-uncollapse-all-button">
              Uncollapse All
            </button>
          </div>
          {/* Filter Input */}
          <div className="referred-filter-container">
            <input
              type="text"
              placeholder="Filter by Name, Email, Homeowner 1, or Referring Agent"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              className="referred-filter-input"
            />
          </div>
          {/* Loading or No Quotes Message */}
          {loading ? (
            <p>Loading...</p>
          ) : filteredQuotes.length === 0 ? (
            <p className="referred-no-quotes-message">No referred quotes found.</p>
          ) : (
            <ul className="referred-quotes-list">
              {filteredQuotes.map((quote) => {
                const standardizedQuoteInfo = standardizeQuoteInfo(quote.quoteInfo || {});
                const formType = standardizedQuoteInfo.formType;
                return (
                  <li key={quote.id} 
                  className="lead-item"
                  onClick={() => toggleQuoteCollapse(quote.id)} // Make the entire quote item clickable
                  style={{ cursor: 'pointer' }}                 
                  
                  >
                    {/* Quote Header */}
                    <div className="referred-quote-header">
                    <span className="toggle-symbol">
      {collapsedQuotes[quote.id] ? '+' : '-'}
    </span>
                      {/* Collapsed Info */}
                      <div className="referred-collapsed-info">
                      <p>
                        <strong>{formType === 'Lender Lead' ? 'Homeowner 1' : 'Name'}:</strong>{' '}
                        {formType === 'Lender Lead'
                          ? `${standardizedQuoteInfo.homeowner1FirstName} ${standardizedQuoteInfo.homeowner1LastName}`
                          : quote.quoteInfo?.name || 'N/A'}
                      </p>
                      <p>
                        <strong>Email:</strong>{' '}
                        {formType === 'Lender Lead'
                          ? standardizedQuoteInfo.homeowner1Email
                          : quote.quoteInfo?.email || 'N/A'}
                      </p>
                      <p>
                        <strong>Referring Agent:</strong>{' '}
                        {quote.currentAgent?.name || 'N/A'}
                      </p>
                    </div>
                    </div>
                    {/* Full Details */}
                    {!collapsedQuotes[quote.id] && (
                      <>
                        <div className="referred-quote-details">
                          <p>
                            <strong>Form Type:</strong> {formType}
                          </p>
                          {formType === 'Lender Lead' ? (
                            <>
                              <p>
                                <strong>Homeowner 1:</strong>{' '}
                                {standardizedQuoteInfo.homeowner1FirstName}{' '}
                                {standardizedQuoteInfo.homeowner1LastName}
                              </p>
                              <p>
                                <strong>Email:</strong>{' '}
                                {standardizedQuoteInfo.homeowner1Email}
                              </p>
                              <p>
                                <strong>Phone:</strong>{' '}
                                {standardizedQuoteInfo.homeowner1Phone}
                              </p>
                              <p>
                                <strong>Birthdate:</strong>{' '}
                                {standardizedQuoteInfo.homeowner1Birthdate}
                              </p>
                              <p>
                                <strong>Homeowner 2:</strong>{' '}
                                {standardizedQuoteInfo.homeowner2FirstName}{' '}
                                {standardizedQuoteInfo.homeowner2LastName}
                              </p>
                              <p>
                                <strong>Email:</strong>{' '}
                                {standardizedQuoteInfo.homeowner2Email}
                              </p>
                              <p>
                                <strong>Phone:</strong>{' '}
                                {standardizedQuoteInfo.homeowner2Phone}
                              </p>
                              <p>
                                <strong>Birthdate:</strong>{' '}
                                {standardizedQuoteInfo.homeowner2Birthdate}
                              </p>
                              <p>
                                <strong>Current Address:</strong>{' '}
                                {standardizedQuoteInfo.currentAddressStreet},{' '}
                                {standardizedQuoteInfo.currentAddressCity},{' '}
                                {standardizedQuoteInfo.currentAddressState},{' '}
                                {standardizedQuoteInfo.currentAddressZip}
                              </p>
                              <p>
                                <strong>New Address:</strong>{' '}
                                {standardizedQuoteInfo.newAddressStreet},{' '}
                                {standardizedQuoteInfo.newAddressCity},{' '}
                                {standardizedQuoteInfo.newAddressState},{' '}
                                {standardizedQuoteInfo.newAddressZip}
                              </p>
                              <p>
                                <strong>Notes:</strong> {standardizedQuoteInfo.notes}
                              </p>
                              <p>
                                <strong>Attached Files:</strong>{' '}
                                {standardizedQuoteInfo.attachedFiles.length > 0
                                  ? standardizedQuoteInfo.attachedFiles.map((file, index) => (
                                      <a
                                        key={index}
                                        href={file}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >{`File ${index + 1}`}</a>
                                    ))
                                  : 'None'}
                              </p>
                            </>
                          ) : (
                            <>
                              <p>
                                <strong>Name:</strong> {quote.quoteInfo?.name || 'N/A'}
                              </p>
                              <p>
                                <strong>Address:</strong>{' '}
                                {quote.quoteInfo?.address?.streetAddress || 'N/A'},{' '}
                                {quote.quoteInfo?.address?.city || 'N/A'},{' '}
                                {quote.quoteInfo?.address?.state || 'N/A'},{' '}
                                {quote.quoteInfo?.address?.zipCode || 'N/A'}
                              </p>
                              <p>
                                <strong>Birthdate:</strong>{' '}
                                {quote.quoteInfo?.birthdate || 'N/A'}
                              </p>
                              <p>
                                <strong>Email:</strong> {quote.quoteInfo?.email || 'N/A'}
                              </p>
                              <p>
                                <strong>Phone:</strong> {quote.quoteInfo?.phone || 'N/A'}
                              </p>
                              <p>
                                <strong>Additional Notes:</strong>{' '}
                                {quote.quoteInfo?.additionalNotes || 'N/A'}
                              </p>
                              <p>
                                <strong>Attachments:</strong>{' '}
                                {quote.quoteInfo?.attachedFiles &&
                                quote.quoteInfo?.attachedFiles.length > 0
                                  ? quote.quoteInfo.attachedFiles.map((file, index) => (
                                      <a
                                        key={index}
                                        href={file}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >{`File ${index + 1}`}</a>
                                    ))
                                  : 'None'}
                              </p>
                            </>
                          )}
                          {/* Common Fields */}
                          <p>
                            <strong>Agent Attached Files:</strong>{' '}
                            {renderAgentAttachedFiles(
                              standardizedQuoteInfo.agentAttachedFiles
                            )}
                          </p>
                          <p>
                            <strong>Carriers to be quoted:</strong>{' '}
                            {quote.carrierCodes && quote.carrierCodes.length > 0
                              ? quote.carrierCodes.join(', ')
                              : 'N/A'}
                          </p>
                          <p>
                            <strong>Referring Agent:</strong>{' '}
                            {quote.currentAgent?.name || 'N/A'},{' '}
                            {quote.currentAgent?.email || 'N/A'},{' '}
                            {quote.currentAgent?.agencyName || 'N/A'}
                          </p>
                        </div>
                        <div className="referred-lead-actions">
                        <button
    onClick={(e) => {
      e.stopPropagation(); // Prevent collapsing on button click
      handleAcceptQuote(quote.id);
    }}
    disabled={quote.quoteInfo?.referralStatus === 'accepted'}
    className="referred-action-button"
  >
                            Accept Quote
                          </button>
                          <button
    onClick={(e) => {
      e.stopPropagation();
      handleCloseQuoteClick(quote);
    }}
    disabled={quote.quoteInfo?.referralStatus === 'closed'}
    className="referred-action-button"
  >
                            Close Quote
                          </button>
                        </div>
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
          {/* Close Quote Modal */}
          {showCloseModal && selectedQuote && (
            <div className="referred-archive-modal">
              <h3 className="referred-archive-modal-title">Close Quote</h3>
              <label className="referred-archive-modal-label">
                Close Reason:
                <select
                  className="referred-archive-modal-select"
                  value={closeReason}
                  onChange={(e) => setCloseReason(e.target.value)}
                >
                  <option value="">Select a reason</option>
                  <option value="successful">Close successful</option>
                  <option value="unsuccessful">Close unsuccessful</option>
                </select>
              </label>
              {closeReason === 'successful' && (
                <label className="referred-archive-modal-label">
                  Carrier:
                  <select
                    className="referred-archive-modal-select"
                    value={selectedCarrier}
                    onChange={(e) => setSelectedCarrier(e.target.value)}
                  >
                    <option value="">Select a carrier</option>
                    {selectedQuote.carrierCodes?.map((carrier, index) => (
                      <option key={index} value={carrier}>
                        {carrier}
                      </option>
                    ))}
                    <option value="other">Other</option>
                  </select>
                </label>
              )}
              {closeReason === 'unsuccessful' && (
                <label className="referred-archive-modal-label">
                  Unsuccessful Reason:
                  <select
                    className="referred-archive-modal-select"
                    value={unsuccessfulReason}
                    onChange={(e) => setUnsuccessfulReason(e.target.value)}
                  >
                    <option value="">Select a reason</option>
                    <option value="could_not_reach">Could not reach</option>
                    <option value="not_competitive">Not competitive</option>
                    <option value="did_not_mean_to_accept">
                      Did not mean to accept
                    </option>
                  </select>
                </label>
              )}
              <button
                onClick={handleCloseQuoteSubmit}
                className="referred-archive-modal-submit"
              >
                Submit
              </button>
              <button
                onClick={() => setShowCloseModal(false)}
                className="referred-archive-modal-cancel"
              >
                Cancel
              </button>
            </div>
          )}
          {/* Payment Calculation Modal */}
          {showPaymentModal && selectedQuote && (
            <PaymentCalculationModal
              isOpen={showPaymentModal}
              onClose={() => setShowPaymentModal(false)}
              onSubmit={handlePaymentModalSubmit}
              annualPremium={annualPremium}
              setAnnualPremium={setAnnualPremium}
            />
          )}
        </>
      )}
    </div>
  );
  
}  

export default BrokerDashReferredQuotes;








