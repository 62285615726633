import React from 'react';
import Modal from 'react-modal';
import './referralDetailsModal.css';

Modal.setAppElement('#root');

const ReferralDetailsModal = ({ isOpen, onClose, referralDetails }) => {
  // Convert the Firestore timestamp to a readable date string
  const formatDate = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      return date.toLocaleString(); // Customize the format as needed
    }
    return 'No timestamp available';
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Referral Details"
      className="ReactModal__Content"
      overlayClassName="ReactModal__Overlay"
    >
      <h2 className="modal-header">Referral Details</h2>
      {referralDetails ? (
        <div className="referral-details">
          <h3 className="section-title">Assigned Agent</h3>
          <p>
            <strong>Name:</strong> {referralDetails.agentName || 'N/A'}
          </p>
          <p>
            <strong>Email:</strong> {referralDetails.agentEmail || 'N/A'}
          </p>
          <p>
            <strong>Carriers:</strong>{' '}
            {referralDetails.carrierCodes
              ? referralDetails.carrierCodes.join(', ')
              : 'N/A'}
          </p>
          <p>
            <strong>Referral Date:</strong> {formatDate(referralDetails.timestamp)}
          </p>
        </div>
      ) : (
        <p>No referral details available.</p>
      )}
      <button onClick={onClose} className="modal-button close-button">
        Close
      </button>
    </Modal>
  );
};

export default ReferralDetailsModal;