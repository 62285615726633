import React, { useState, useEffect } from 'react';
import Navbar from '../../Navbar/Navbar';
import Bottom from '../../HomePage/HomePageComponents/hompage-bottom/homepage-bottom';
import BundleInsuranceAddress from './bundleinsuranceinfoAddress';
import BundleInsuranceBirthdate from './bundleinsuranceinfoBirthdate';
import BundleInsuranceContact from './bundleinsuranceinfoContact';
import './bundleinsuranceinfo.css';
import { getFunctions, httpsCallable } from 'firebase/functions';
import QuoteInfoFileUpload from '../quoteInfoFileUpload';
import QuoteInfoModal from '../quoteInfoModal';

function BundleQuotePage1() {
  const initialFormData = {
    streetAddress: '',
    state: '',
    zipCode: '',
    day: '',
    month: '',
    year: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    additionalNotes: '',
    formType: 'bundleQuote',
    fileURLs: [], // Initialize as an array
    agentCode: '' // Initialize the agentCode field
  };

  const [formData, setFormData] = useState(initialFormData);
  const [currentStep, setCurrentStep] = useState(1);
  const [submissionStatus, setSubmissionStatus] = useState({
    isSubmitted: false,
    isSuccess: null,
    errorMessage: null,
  });

  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [documentsUploaded, setDocumentsUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const agentCode = params.get('agentCode');
    if (agentCode) {
      setFormData(prevFormData => ({ ...prevFormData, agentCode }));
    }
  }, []);

  // Firebase functions initialization
  const firebaseFunctions = getFunctions();
  const sendEmail = httpsCallable(firebaseFunctions, 'sendEmail');

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleChange = (fieldName, value) => {
    setFormData({ ...formData, [fieldName]: value });
  };

  const handleDocumentUpload = (url) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      fileURLs: [...prevFormData.fileURLs, url]
    }));
    setDocumentsUploaded(true);
  };

  const handleSubmit = async () => {
    setSubmissionStatus({ isSubmitted: true, isSuccess: null, errorMessage: null });
    setIsLoading(true);

    try {
      const result = await sendEmail({ formData, type: 'formSubmission' });
      console.log('Email sent successfully:', result);
      setSubmissionStatus({ isSubmitted: true, isSuccess: true, errorMessage: null });
      setShowConfirmationModal(true);
      setModalMessage("Good News! Your information has been received and your quote is being worked on by one of our professional agents. Within 24 hours you will receive a request for a 15 minute phone call to make sure everything is accurate.");
    } catch (error) {
      console.error('Error sending email:', error);
      setSubmissionStatus({ isSubmitted: true, isSuccess: false, errorMessage: error.message });
      setModalMessage(`Error: ${error.message}`);
      setShowConfirmationModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (currentStep === 3) {
      setShowModal(true); // Show the upload modal before submitting
    } else {
      handleNext();
    }
  };

  const proceedWithSubmission = () => {
    setShowModal(false);
    handleSubmit(); // Submit form data along with file URLs after confirming file upload
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  return (
    <div className="bundlequotepage1-page">
      <Navbar />
      <div className='bundlequotepage1-section'>
        <div className='bundlequotepage1-header'>
          <img src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Ficon-bundle-solid.png?alt=media&token=273a81a8-3f00-4203-a7aa-f4e9a7a01972' alt="Bundle Icon"/>
          <h1>Home and Auto Insurance Bundle Quote</h1>
        </div>
        <form onSubmit={handleFormSubmit}>
          {currentStep === 1 && <BundleInsuranceAddress formData={formData} handleChange={handleChange} />}
          {currentStep === 2 && <BundleInsuranceBirthdate formData={formData} handleChange={handleChange} />}
          {currentStep === 3 && <BundleInsuranceContact formData={formData} handleChange={handleChange} />}
          
          <div className='quotepagebutton-div'>
            {currentStep > 1 && <button className='quotepagebutton' type="button" onClick={handlePrevious}>Previous</button>}
            {currentStep < 3 && <button className='quotepagebutton' type="button" onClick={handleNext}>Next</button>}
            {currentStep === 3 && <button id="submitBtn" className='quotepagebutton' type="submit">Submit</button>}
          </div>
        </form>
      </div>
      <Bottom />

      {showModal && (
        <div className="save-time-money-modal-overlay">
          <div className="save-time-money-modal">
            <button className="save-time-money-close-button" onClick={closeModal}>&times;</button>
            <div className='drag-drop-box'>
              <div className='contact-save-more-money'>
                <br />
                <h3 className="drag-drop-box-title"><span id="happens-next-bold">Before you go...</span></h3>
                <p className='save-time-money-upload-paragraph'>Save time by uploading your current policy documents. <br /> <br /> Or click the blue button below to submit your ABC's and we'll get started on your quotes!</p>
                <ol className='save-time-money-ordered-list'>
                  <li>For security purposes, log in or sign up.</li>
                  <li>Once logged in, upload your files by selecting "Choose Files" or simply drag and drop your documents.</li>
                  <li>Once your files are attached click "Upload"</li>
                  <li>Click blue submit button</li>
                </ol>
                <p className='save-time-money-find-help'>Not sure how to find your policy documents? <a href="./decpagehelp">Click here</a> for help.</p>
              </div>
              <QuoteInfoFileUpload onUpload={handleDocumentUpload} />
              <div className="modal-buttons">
                {documentsUploaded ? (
                  <button id="all-done-uploading" onClick={proceedWithSubmission}>All done uploading, please submit</button>
                ) : (
                  <button id="no-thanks-submit" onClick={proceedWithSubmission}>No Thanks, Just Submit</button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <QuoteInfoModal show={showConfirmationModal} message={modalMessage} onClose={closeConfirmationModal} />

      {isLoading && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
}

export default BundleQuotePage1;


