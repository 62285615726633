import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { auth, db } from '../../firebaseConfig';
import { collection, getDocs, doc, getDoc, Timestamp, setDoc } from 'firebase/firestore';
import LenderQuoteFileUpload from './lenderQuoteFileUpload';
import './lenderLead.css';

const NewCustomerForm = () => {
  const [currentAddress, setCurrentAddress] = useState({
    street: '',
    city: '',
    state: '',
    zip: '',
  });
  const [newAddress, setNewAddress] = useState({
    street: '',
    city: '',
    state: '',
    zip: '',
  });
  const [homeowner1, setHomeowner1] = useState({
    firstName: '',
    lastName: '',
    birthdate: '',
    email: '',
    phone: '',  // Add phone field
  });
  const [homeowner2, setHomeowner2] = useState({
    firstName: '',
    lastName: '',
    birthdate: '',
    email: '',
    phone: '',  // Add phone field
  });
  const [notes, setNotes] = useState('');
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState('');
  const [defaultAgentEmail, setDefaultAgentEmail] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state for form submission
  const [uploadLoading, setUploadLoading] = useState(false); // Loading state for file upload
  const [uploadSuccess, setUploadSuccess] = useState(false); // Success state for file upload
  const [uploadedFiles, setUploadedFiles] = useState([]);  // State to store uploaded file URLs

  useEffect(() => {
    const fetchDefaultAgent = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'lenders', user.uid));
          if (userDoc.exists()) {
            const defaultAgentId = userDoc.data().defaultAgent;
            if (defaultAgentId) {
              const agentDoc = await getDoc(doc(db, 'michiganagentlist', defaultAgentId));
              if (agentDoc.exists()) {
                setDefaultAgentEmail(agentDoc.data().email);
                setSelectedAgent(agentDoc.id); // Pre-set the selected agent
                console.log('Default agent pre-set in NewCustomerForm:', agentDoc.data()); // Log for debugging
              }
            }
          }
        } catch (err) {
          console.error('Error fetching default agent:', err);
        }
      }
    };

    const fetchAgents = async () => {
      try {
        const agentsCollection = collection(db, 'michiganagentlist');
        const agentsSnapshot = await getDocs(agentsCollection);
        const agentsList = agentsSnapshot.docs.map(doc => ({
          agentId: doc.id,
          name: doc.data().name,
          email: doc.data().email
        }));
        setAgents(agentsList);
      } catch (err) {
        console.error('Error fetching agents:', err);
      }
    };

    fetchDefaultAgent();
    fetchAgents();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const user = auth.currentUser;
    if (!user) {
        setError('You must be logged in to submit the form.');
        setLoading(false);
        return;
    }

    let agentEmail;

    // Step 1: Check if there is a selected agent (excluding "Assign By Zip Code")
    if (selectedAgent && selectedAgent !== "Assign By Zip Code") {
        agentEmail = agents.find(agent => agent.agentId === selectedAgent)?.email;
        console.log("Selected agent email:", agentEmail); // Log selected agent
    }

    // Step 2: If "Assign By Zip Code" is selected, get the next agent in the round-robin assignment
    if (!agentEmail && selectedAgent === "Assign By Zip Code") {
        console.log("Assigning by Zip Code, attempting round-robin assignment...");
        try {
            const zipCode = currentAddress.zip; // Get the zip code from the current address
            const functions = getFunctions();
            const assignAgentFunction = httpsCallable(functions, 'assignAgentForLender');
            const result = await assignAgentFunction({ zipCode }); // Call your cloud function

            if (result.data && result.data.email) {
                agentEmail = result.data.email; // Assuming your function returns the agent's email
                console.log("Assigned agent email from round-robin:", agentEmail); // Log assigned agent from round-robin
            } else {
                console.warn("No agents available for round-robin assignment.");
            }
        } catch (error) {
            console.error("Error during round-robin assignment:", error);
        }
    }

    // Step 3: If no agent is assigned, fall back to the default agent
    if (!agentEmail) {
        console.log("Assigning default agent...");
        agentEmail = defaultAgentEmail; // Use the default agent's email
        console.log("Default agent email assigned:", agentEmail); // Log default agent
    }

    // Check for missing essential fields
    if (!agentEmail) {
        setError('Agent email not found. Please select an agent or check the zip code.');
        setLoading(false);
        return;
    }
    if (!homeowner1.email) {
        setError('Homeowner 1 email is required.');
        setLoading(false);
        return;
    }

    const formData = {
        currentAddress,
        newAddress,
        homeowner1,
        homeowner2,
        notes,
        selectedAgent: agentEmail,
        lenderEmail: user.email,
        uid: user.uid,
        timestamp: Timestamp.now(),
        attachedFiles: uploadedFiles,
        zipCode: currentAddress.zip,
        brokerArchived: false, // default value
        archiveReason: '', // default value
        writtenCarrier: '', // default value
    };

    try {
        const functions = getFunctions();

        // Create a new document reference
        const newLeadRef = doc(collection(db, 'lenderLeads')); // Create a reference for the lenderLeads collection
        await setDoc(newLeadRef, formData); // Write to lenderLeads
        console.log('Lead data saved to lenderLeads:', formData); // Log confirmation

        // Use the same document ID for lenderMyQuotes
        const newQuoteRef = doc(collection(db, 'lenderMyQuotes'), newLeadRef.id); // Use the same ID
        await setDoc(newQuoteRef, {
            agentEmail,
            agentName: selectedAgent ? agents.find(agent => agent.agentId === selectedAgent)?.name : '',
            homeownerEmail: homeowner1.email,
            homeownerName: `${homeowner1.firstName} ${homeowner1.lastName}`,
            lenderEmail: user.email,
            uid: user.uid,
            timestamp: Timestamp.now(),
            submittedPayment: 0, // Set to 0 initially or adjust as needed
        });
        console.log('Quote data saved to lenderMyQuotes with ID:', newLeadRef.id); // Log confirmation

        // Invoke the sendLenderLeadEmail function
        const sendLenderLeadEmail = httpsCallable(functions, 'sendLenderLeadEmail');
        await sendLenderLeadEmail({ formData });

        alert('Customer added successfully');
        
        // Reset form fields
        setCurrentAddress({ street: '', city: '', state: '', zip: '' });
        setNewAddress({ street: '', city: '', state: '', zip: '' });
        setHomeowner1({ firstName: '', lastName: '', birthdate: '', email: '', phone: '' });
        setHomeowner2({ firstName: '', lastName: '', birthdate: '', email: '', phone: '' });
        setNotes('');
        // Do not reset selectedAgent
        setUploadedFiles([]);
    } catch (error) {
        console.error('Error adding customer:', error);
        setError('Error adding customer.');
    } finally {
        setLoading(false);
    }
}

  
  
  const handleUpload = (files) => {
    setUploadedFiles(files);  // Store uploaded files in state
  };

  const handleChange = (e, section) => {
    const { name, value } = e.target;
    if (section === 'currentAddress') {
      setCurrentAddress({ ...currentAddress, [name]: value });
    } else if (section === 'newAddress') {
      setNewAddress({ ...newAddress, [name]: value });
    } else if (section === 'homeowner1') {
      setHomeowner1({ ...homeowner1, [name]: value });
    } else if (section === 'homeowner2') {
      setHomeowner2({ ...homeowner2, [name]: value });
    } else if (section === 'notes') {
      setNotes(value);
    }
  };

  return (
    <div className="new-customer-form-container">
      <form className="new-customer-form" onSubmit={handleSubmit}>
        <h2>Submit Customer for Quote</h2>

        {error && <p className="error">{error}</p>}

        <div className="lender-quote-address-sections">
          <div id="lender-quote-current-address" className="lender-quote-submit-section">
            <div className="lender-quote-section-label">Current Address</div>
            <input className='lender-quote-input'
              type="text"
              placeholder="Current Street Address"
              name="street"
              value={currentAddress.street}
              onChange={(e) => handleChange(e, 'currentAddress')}
              required
            />
            <input className='lender-quote-input'
              type="text"
              placeholder="Current City"
              name="city"
              value={currentAddress.city}
              onChange={(e) => handleChange(e, 'currentAddress')}
              required
            />
            <input className='lender-quote-input'
              type="text"
              placeholder="Current State"
              name="state"
              value={currentAddress.state}
              onChange={(e) => handleChange(e, 'currentAddress')}
              required
            />
            <input className='lender-quote-input'
              type="text"
              placeholder="Current Zip Code"
              name="zip"
              value={currentAddress.zip}
              onChange={(e) => handleChange(e, 'currentAddress')}
              required
            />
          </div>

          <div id="lender-quote-new-address" className="lender-quote-submit-section">
            <div className="lender-quote-section-label">New Address</div>
            <input className='lender-quote-input'
              type="text"
              placeholder="New Street Address"
              name="street"
              value={newAddress.street}
              onChange={(e) => handleChange(e, 'newAddress')}
              required
            />
            <input className='lender-quote-input'
              type="text"
              placeholder="New City"
              name="city"
              value={newAddress.city}
              onChange={(e) => handleChange(e, 'newAddress')}
              required
            />
            <input className='lender-quote-input'
              type="text"
              placeholder="New State"
              name="state"
              value={newAddress.state}
              onChange={(e) => handleChange(e, 'newAddress')}
              required
            />
            <input className='lender-quote-input'
              type="text"
              placeholder="New Zip Code"
              name="zip"
              value={newAddress.zip}
              onChange={(e) => handleChange(e, 'newAddress')}
              required
            />
          </div>
        </div>

        <div className="lender-quote-homeowner-sections">
        <div className="lender-quote-submit-section">
  <div className="lender-quote-section-label">Homeowner 1</div>
  <input className='lender-quote-input'
    type="text"
    placeholder="First Name"
    name="firstName"
    value={homeowner1.firstName}
    onChange={(e) => handleChange(e, 'homeowner1')}
    required
  />
  <input className='lender-quote-input'
    type="text"
    placeholder="Last Name"
    name="lastName"
    value={homeowner1.lastName}
    onChange={(e) => handleChange(e, 'homeowner1')}
    required
  />
  <label>Date of Birth</label>
  <input className='lender-quote-input'
    type="date"
    name="birthdate"
    value={homeowner1.birthdate}
    onChange={(e) => handleChange(e, 'homeowner1')}
    required
  />
  <input className='lender-quote-input'
    type="email"
    placeholder="Email"
    name="email"
    value={homeowner1.email}
    onChange={(e) => handleChange(e, 'homeowner1')}
    required
  />
  <input className='lender-quote-input'
    type="tel"
    placeholder="Phone Number"
    name="phone"
    value={homeowner1.phone}
    onChange={(e) => handleChange(e, 'homeowner1')}  // Add phone number input
    required
  />
</div>

<div className="lender-quote-submit-section">
  <div className="lender-quote-section-label">Homeowner 2</div>
  <input className='lender-quote-input'
    type="text"
    placeholder="First Name"
    name="firstName"
    value={homeowner2.firstName}
    onChange={(e) => handleChange(e, 'homeowner2')}
  />
  <input className='lender-quote-input'
    type="text"
    placeholder="Last Name"
    name="lastName"
    value={homeowner2.lastName}
    onChange={(e) => handleChange(e, 'homeowner2')}
  />
  <label>Date of Birth</label>
  <input className='lender-quote-input'
    type="date"
    name="birthdate"
    value={homeowner2.birthdate}
    onChange={(e) => handleChange(e, 'homeowner2')}
  />
  <input className='lender-quote-input'
    type="email"
    placeholder="Email"
    name="email"
    value={homeowner2.email}
    onChange={(e) => handleChange(e, 'homeowner2')}
  />
  <input className='lender-quote-input'
    type="tel"
    placeholder="Phone Number"
    name="phone"
    value={homeowner2.phone}
    onChange={(e) => handleChange(e, 'homeowner2')}  // Add phone number input
  />
</div>

        </div>

        <textarea
          className="textarea"
          placeholder="Additional Notes"
          value={notes}
          onChange={(e) => handleChange(e, 'notes')}
        />

        <div className="agent-selection">
          <label htmlFor="agent">Select Agent for this Quote (go to "My Account" to set default agent):</label>
          <select
            id="agent"
            value={selectedAgent}
            onChange={(e) => setSelectedAgent(e.target.value)}
            required
          >
            <option value="" disabled>Select an agent</option>
            {agents.map(agent => (
              <option key={agent.agentId} value={agent.agentId}>
                {agent.name} ({agent.email})
              </option>
            ))}
          </select>
        </div>

        <LenderQuoteFileUpload
          onUpload={handleUpload}
          selectedAgentEmail={agents.find(agent => agent.agentId === selectedAgent)?.email || defaultAgentEmail}
        />

        {uploadLoading && <div className="loader">Uploading...</div>} {/* Show loader during upload */}

        <button type="submit" className="lender-lead-submit-button" disabled={loading}>
          {loading ? <div className="loader">Submitting...</div> : 'Submit Customer for Quote'}
        </button>
      </form>
    </div>
  );
};

export default NewCustomerForm;








