import React, { useState } from 'react';
import { getFunctions } from 'firebase/functions';
import { getAuth } from 'firebase/auth';

const CreateBrokerUser = () => {
  const [email, setEmail] = useState('');
  const [tempPassword, setTempPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const functions = getFunctions();
  
  const handleCreateUser = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      const response = await fetch('https://us-central1-abc-quote.cloudfunctions.net/updatePassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await getToken()}`,
        },
        body: JSON.stringify({ email, password: tempPassword }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update password');
      }

      setMessage(`Temporary password set for ${email}.`);
    } catch (err) {
      console.error('Error updating user password:', err);
      setError('Error setting up temporary password. Please check the email and try again.');
    }
  };

  return (
    <div>
      <h2>Create Broker User</h2>
      <form onSubmit={handleCreateUser}>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Temporary Password:</label>
          <input
            type="password"
            value={tempPassword}
            onChange={(e) => setTempPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Create User</button>
      </form>
      {message && <p>{message}</p>}
      {error && <p>{error}</p>}
    </div>
  );
};

async function getToken() {
  const user = getAuth().currentUser;
  if (user) {
    return user.getIdToken();
  }
  throw new Error('User not authenticated');
}

export default CreateBrokerUser;
