import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import Navbar from '../../Navbar/Navbar';
import ForgotPasswordButton from '../../ForLenders/LenderLogin/forgotPasswordButton';
import './brokerLogin.css';

const auth = getAuth();
const db = getFirestore();

const BrokerLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        const uid = user.uid;

        // Fetch roles and temporaryPassword from Firestore using uid
        const userRolesRef = doc(db, 'userRoles', uid);
        const userRolesSnapshot = await getDoc(userRolesRef);

        if (userRolesSnapshot.exists()) {
            const userRolesDoc = userRolesSnapshot.data();
            const roles = userRolesDoc.roles || {};
            const temporaryPassword = userRolesDoc.temporaryPassword;

            if (roles.brokerLogin) {
                if (temporaryPassword) {
                    // Redirect to the reset password page if the user is using a temporary password
                    navigate('/reset-initial-password');
                } else if (!user.emailVerified) {
                    setError('Please verify your email before accessing the dashboard.');
                    await signOut(auth);
                } else {
                    navigate('/broker-dashboard'); // Allow access to the dashboard
                }
            } else {
                setError('You do not have access to this dashboard.');
                await signOut(auth);
            }
        } else {
            setError('User roles not found. Please contact support.');
            await signOut(auth);
        }
    } catch (error) {
        console.error('Error logging in:', error);
        setError('Error logging in. Please try again.');
    }
};


  return (
    <div>
      <Navbar />
      <div className="broker-login-container">
        <h1>Broker Login</h1>
        <h2>Welcome Back! Please Log Into Your Dashboard Here</h2>
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button className='broker-login-button' type="submit">Login</button>
          {error && <p className="error-message">{error}</p>}
        </form>
        <ForgotPasswordButton />
      </div>
    </div>
  );
};

export default BrokerLogin;







