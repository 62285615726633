import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../Navbar/Navbar';
import './brokerMyAccount.css'; // Import the CSS file for styling

const auth = getAuth();
const db = getFirestore();

const BrokerMyAccount = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [paymentsInfo, setPaymentsInfo] = useState(null); // What you owe to others
  const [incomeInfo, setIncomeInfo] = useState(null); // What others owe to you
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isExpandedPayments, setIsExpandedPayments] = useState(false); // Track itemized expansion for agentPayments
  const [isExpandedIncome, setIsExpandedIncome] = useState(false); // Track itemized expansion for agentIncome
  const [expandedMonthsPayments, setExpandedMonthsPayments] = useState({}); // Track expansion for previous months of agentPayments
  const [expandedMonthsIncome, setExpandedMonthsIncome] = useState({}); // Track expansion for previous months of agentIncome
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      const user = auth.currentUser;
      if (user) {
        try {
          // Fetch agent info
          const userDoc = await getDoc(doc(db, 'michiganagentlist', user.uid));
          if (userDoc.exists()) {
            setUserInfo(userDoc.data());
          } else {
            setError('No profile data found');
          }

          // Fetch agentPayments (what you owe others)
          const paymentsDoc = await getDoc(doc(db, 'agentPayments', user.uid));
          if (paymentsDoc.exists()) {
            setPaymentsInfo(paymentsDoc.data());
          } else {
            // Set default values for payments if no document exists
            setPaymentsInfo({
              currentMonthOwed: 0,
              currentMonthPayments: [],
              monthlyPaymentsArchive: {}
            });
          }

          // Fetch agentIncome (what others owe you)
          const incomeDoc = await getDoc(doc(db, 'agentIncome', user.uid));
          if (incomeDoc.exists()) {
            setIncomeInfo(incomeDoc.data());
          } else {
            // Set default values for income if no document exists
            setIncomeInfo({
              currentMonthIncome: 0,
              currentMonthPayments: [],
              totalIncomeEver: 0,
              monthlyIncomeArchive: {}
            });
          }

        } catch (err) {
          setError('Error fetching data');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, []);

  // Function to parse "Month Year" strings into Date objects
  const parseMonthYear = (monthYearStr) => {
    const [monthName, year] = monthYearStr.split(' ');
    const month = new Date(Date.parse(`${monthName} 1, ${year}`)).getMonth();
    return new Date(year, month);
  };

  // Helper function to toggle expansion for previous months
  const toggleMonth = (monthKey, type) => {
    if (type === 'payments') {
      setExpandedMonthsPayments((prevState) => ({
        ...prevState,
        [monthKey]: !prevState[monthKey],
      }));
    } else {
      setExpandedMonthsIncome((prevState) => ({
        ...prevState,
        [monthKey]: !prevState[monthKey],
      }));
    }
  };

  const currentMonthOwed = paymentsInfo?.currentMonthOwed || 0;
  const currentMonthIncome = incomeInfo?.currentMonthIncome || 0;
  const totalIncomeEver = incomeInfo?.totalIncomeEver || 0;

  if (loading) {
    return (
      <div>
        <Navbar />
        <div className="broker-my-account-container">
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div>
    <Navbar />
    <div className="broker-my-account-container">
    <div className="broker-my-account-header">
          <button
            onClick={() => navigate('/broker-dashboard')}
            className="broker-account-dashboard-button"
          >
            Back to Dashboard
          </button>
          <h1>My Account</h1>
        </div>

      {/* Display Agent Information */}
      <div className="broker-my-account-info">
        <p>
          <strong>Name:</strong> {userInfo?.name || 'N/A'}
        </p>
        <div className="address-section">
          <p>
            <strong>Agency Name:</strong> {userInfo?.agencyName || 'N/A'}
          </p>
          <p>
            <strong>Street Address:</strong> {userInfo?.streetAddress || 'N/A'}
          </p>
          <p>
            <strong>City:</strong> {userInfo?.city || 'N/A'}, <strong>State:</strong>{' '}
            {userInfo?.state || 'N/A'}
          </p>
        </div>
        <p className="full-width">
          <strong>Agency Website:</strong> {userInfo?.agencyWebsite || 'N/A'}
        </p>
        <p>
          <strong>Agent ID:</strong> {userInfo?.agentId || 'N/A'}
        </p>
        <p>
          <strong>Email:</strong> {userInfo?.email || 'N/A'}
        </p>
        <p>
          <strong>Assigned Zip Codes:</strong>{' '}
          {userInfo?.assignedZipCodes?.join(', ') || 'N/A'}
        </p>
      </div>

      {/* Section for what you owe to others (agentPayments) */}
      <div className="section payments-section">
        <h2>Current Month Payments Owed to Others</h2>
        <p>
          <strong>Total Owed:</strong> ${currentMonthOwed.toFixed(2)}
        </p>

        {/* Expandable Section for Itemized Payments Owed */}
        <button onClick={() => setIsExpandedPayments(!isExpandedPayments)}>
          {isExpandedPayments ? 'Hide Itemized Payments' : 'View Itemized Payments'}
        </button>

        {isExpandedPayments && (
          <div className="itemized-payments">
            <ul>
              {paymentsInfo.currentMonthPayments &&
              paymentsInfo.currentMonthPayments.length > 0 ? (
                paymentsInfo.currentMonthPayments.map((payment, index) => (
                  <li key={index} className='itemized-payment-item'>
                    <p>
                      <strong>Referring Agent:</strong> {payment.referringAgent?.name || 'N/A'} (
                      {payment.referringAgent?.email || 'N/A'})
                    </p>
                    <p>
                      <strong>Date Referred:</strong>{' '}
                      {payment.dateReferred
                        ? new Date(payment.dateReferred.seconds * 1000).toLocaleDateString()
                        : 'N/A'}
                    </p>
                    <p>
                      <strong>Client Name:</strong> {payment.clientName || 'N/A'}
                    </p>
                    <p>
                      <strong>Referral Amount:</strong> $
                      {payment.referralAmount?.toFixed(2) || 0}
                    </p>
                  </li>
                ))
              ) : (
                <p>No itemized payments available</p>
              )}
            </ul>
          </div>
        )}

        {/* Previous 3 Months Payment History (Owed to Others) */}
        <h2>Previous 3 Months Payment History (Owed to Others)</h2>
        <ul>
          {paymentsInfo?.monthlyPaymentsArchive &&
          Object.keys(paymentsInfo.monthlyPaymentsArchive).length > 0 ? (
            Object.keys(paymentsInfo.monthlyPaymentsArchive)
              .sort((a, b) => parseMonthYear(a) - parseMonthYear(b))
              .slice(-3) // Get the last 3 months after sorting
              .map((monthKey) => {
                const monthData = paymentsInfo.monthlyPaymentsArchive[monthKey];
                const isMonthExpanded = expandedMonthsPayments[monthKey];

                return (
                  <li key={monthKey} className='itemized-payment-item'>
                    <strong>{monthKey}:</strong> ${monthData?.amount?.toFixed(2) || 0}
                    <button
                      className="month-toggle-button"
                      onClick={() => toggleMonth(monthKey, 'payments')}
                    >
                      {isMonthExpanded ? 'Hide Itemized Payments' : 'Show Itemized Payments'}
                    </button>
                    {isMonthExpanded && (
                      <ul>
                        {monthData?.payments?.map((payment, index) => (
                          <li key={index} className='itemized-payment-item'>
                            <p>
                              <strong>Referring Agent:</strong>{' '}
                              {payment.referringAgent?.name || 'N/A'} (
                              {payment.referringAgent?.email || 'N/A'})
                            </p>
                            <p>
                              <strong>Client Name:</strong> {payment.clientName || 'N/A'}
                            </p>
                            <p>
                              <strong>Referral Amount:</strong> $
                              {payment.referralAmount?.toFixed(2) || 0}
                            </p>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                );
              })
          ) : (
            <p>No payment history available</p>
          )}
        </ul>
      </div>

      {/* Section for what others owe to you (agentIncome) */}
      <div className="section income-section">
        <h2>Current Month Referral Payments Owed to Me</h2>
        <p>
          <strong>Total for {new Date().toLocaleString('default', { month: 'long' })}:</strong> $
          {currentMonthIncome.toFixed(2)}
        </p>

        {/* Expandable Section for Itemized Payments Owed */}
        <button onClick={() => setIsExpandedIncome(!isExpandedIncome)}>
          {isExpandedIncome ? 'Hide Itemized Payments' : 'View Itemized Payments'}
        </button>

        {isExpandedIncome && (
          <div className="itemized-income">
            <ul>
              {incomeInfo.currentMonthPayments && incomeInfo.currentMonthPayments.length > 0 ? (
                incomeInfo.currentMonthPayments.map((payment, index) => (
                  <li key={index} className='itemized-payment-item'>
                    <p>
                      <strong>Referring Agent:</strong> {payment.referringAgent?.name || 'N/A'} (
                      {payment.referringAgent?.email || 'N/A'})
                    </p>
                    <p>
                      <strong>Date Referred:</strong>{' '}
                      {payment.dateReferred
                        ? new Date(payment.dateReferred.seconds * 1000).toLocaleDateString()
                        : 'N/A'}
                    </p>
                    <p>
                      <strong>Client Name:</strong> {payment.clientName || 'N/A'}
                    </p>
                    <p>
                      <strong>Referral Amount:</strong> $
                      {payment.referralAmount?.toFixed(2) || 0}
                    </p>
                  </li>
                ))
              ) : (
                <p>No itemized payments available</p>
              )}
            </ul>
          </div>
        )}

        {/* Previous 3 Months Payment History (Owed to Me) */}
        <h2>Previous 3 Months Payment History (Owed to Me)</h2>
        <ul>
          {incomeInfo?.monthlyIncomeArchive &&
          Object.keys(incomeInfo.monthlyIncomeArchive).length > 0 ? (
            Object.keys(incomeInfo.monthlyIncomeArchive)
              .sort((a, b) => parseMonthYear(a) - parseMonthYear(b))
              .slice(-3) // Get the last 3 months after sorting
              .map((monthKey) => {
                const monthData = incomeInfo.monthlyIncomeArchive[monthKey];
                const isMonthExpanded = expandedMonthsIncome[monthKey];

                return (
                  <li key={monthKey} className='itemized-payment-item'>
                    <strong>{monthKey}:</strong> ${monthData?.amount?.toFixed(2) || 0}
                    <button
                      className="month-toggle-button"
                      onClick={() => toggleMonth(monthKey, 'income')}
                    >
                      {isMonthExpanded ? 'Hide Itemized Payments' : 'Show Itemized Payments'}
                    </button>
                    {isMonthExpanded && (
                      <ul>
                        {monthData?.payments?.map((payment, index) => (
                          <li key={index} className='itemized-payment-item'>
                            <p>
                              <strong>Referring Agent:</strong>{' '}
                              {payment.referringAgent?.name || 'N/A'} (
                              {payment.referringAgent?.email || 'N/A'})
                            </p>
                            <p>
                              <strong>Client Name:</strong> {payment.clientName || 'N/A'}
                            </p>
                            <p>
                              <strong>Referral Amount:</strong> $
                              {payment.referralAmount?.toFixed(2) || 0}
                            </p>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                );
              })
          ) : (
            <p>No payment history available</p>
          )}
        </ul>
      </div>


    </div>
  </div>
);
};

export default BrokerMyAccount;