import React, { useState, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { getFirestore, doc, updateDoc, collection, query, where, getDocs, getDoc, onSnapshot } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import ReferOutModal from './referOutModal';
import ReferralDetailsModal from './referralDetailsModal';
import AgentFileUpload from './agentFileUpload';
import './brokerDashAssigned.css';


const BrokerDashAssigned = () => {
    const auth = getAuth();
    const db = getFirestore();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [assignedAgentsQuotes, setAssignedAgentsQuotes] = useState([]);
    const [selectedQuote, setSelectedQuote] = useState(null);
    const [currentAgent, setCurrentAgent] = useState({});
    const [modalAgents, setModalAgents] = useState([]);
    const [selectedAgents, setSelectedAgents] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [referralDetails, setReferralDetails] = useState(null);
    const [isReferralDetailsModalOpen, setIsReferralDetailsModalOpen] = useState(false);
    const [isAgentModalOpen, setIsAgentModalOpen] = useState(false); // State for the file upload modal
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
    const [archiveReason, setArchiveReason] = useState('');
    const [selectedCarrier, setSelectedCarrier] = useState('');
    const [unsuccessfulReason, setUnsuccessfulReason] = useState('');
    const [otherCarrier, setOtherCarrier] = useState(''); // Add this state to handle "Other" input
    const [agentCarriers, setAgentCarriers] = useState([]); // State to store the agent's carriers
    const [filterText, setFilterText] = useState(''); // State for the filter input
    const [collapsedQuotes, setCollapsedQuotes] = useState({});
    const [isSectionCollapsed, setIsSectionCollapsed] = useState(true);





    useEffect(() => {
      const user = auth.currentUser;
      if (!user) {
        setError('You must be logged in to view your quotes.');
        setLoading(false);
        return;
      }
    
      let unsubscribeReferrals;
      let unsubscribeAssignedAgents;
    
      const fetchAgentData = async () => {
        setLoading(true);
    
        try {
          const userDoc = await getDoc(doc(db, 'userRoles', user.uid));
          if (!userDoc.exists() || !userDoc.data().roles.brokerLogin) {
            setError('You do not have access to this dashboard.');
            await signOut(auth);
            navigate('/');
            return;
          }
    
          const referralsQuery = query(
            collection(db, 'agentReferrals'),
            where('currentAgent.email', '==', user.email)
          );
    
          const assignedAgentsQuery = query(
            collection(db, 'assignedAgents'),
            where('agentEmail', '==', user.email),
            where('brokerArchived', '==', false)
          );
    
          // Set up real-time listeners
          unsubscribeReferrals = onSnapshot(referralsQuery, (referralsSnapshot) => {
            processSnapshots(referralsSnapshot, null);
          });
    
          unsubscribeAssignedAgents = onSnapshot(assignedAgentsQuery, (assignedAgentsSnapshot) => {
            processSnapshots(null, assignedAgentsSnapshot);
          });
        } catch (err) {
          console.error('Error fetching data:', err);
          setError('Error fetching data.');
          setLoading(false);
        }
      };
    
      // Function to process snapshots
      const processSnapshots = async (referralsSnapshot, assignedAgentsSnapshot) => {
        try {
          // If snapshots are null, fetch them
          if (!referralsSnapshot) {
            const referralsQuery = query(
              collection(db, 'agentReferrals'),
              where('currentAgent.email', '==', user.email)
            );
            referralsSnapshot = await getDocs(referralsQuery);
          }
          if (!assignedAgentsSnapshot) {
            const assignedAgentsQuery = query(
              collection(db, 'assignedAgents'),
              where('agentEmail', '==', user.email),
              where('brokerArchived', '==', false)
            );
            assignedAgentsSnapshot = await getDocs(assignedAgentsQuery);
          }
    
          // Build a map of referral statuses and closing details by quoteInfo.id
          const referralsStatusMap = referralsSnapshot.docs.reduce((acc, doc) => {
            const referralData = doc.data();
            const referralId = referralData.quoteInfo?.id;
            if (referralId) {
              acc[referralId] = {
                referralStatus: referralData.quoteInfo?.referralStatus || 'Not Referred',
                closeReason: referralData.closeReason || 'pending',
                writtenCarrier: referralData.writtenCarrier || 'N/A',
                unsuccessfulReason: referralData.unsuccessfulReason || 'N/A',
              };
            }
            return acc;
          }, {});
    
          // Combine assigned agents with the referral status and close details
          const combinedQuotes = assignedAgentsSnapshot.docs.map((assignedDoc) => {
            const assignedData = assignedDoc.data();
            const referralInfo = referralsStatusMap[assignedDoc.id] || {
              referralStatus: 'Not Referred',
              closeReason: 'pending',
              writtenCarrier: 'N/A',
              unsuccessfulReason: 'N/A',
            };
            return {
              id: assignedDoc.id,
              ...assignedData,
              ...referralInfo,
            };
          });
    
          setAssignedAgentsQuotes(combinedQuotes);
          setLoading(false);
         // Initialize collapsedQuotes state for new quotes only
      setCollapsedQuotes((prevState) => {
        const newState = { ...prevState };
        combinedQuotes.forEach((quote) => {
          if (!(quote.id in newState)) {
            newState[quote.id] = true; // Default to collapsed
          }
        });
        return newState;
      });
    } catch (error) {
      console.error('Error processing snapshots:', error);
      setError('Error processing data.');
      setLoading(false);
    }
  };

  fetchAgentData();

  // Cleanup subscriptions on unmount
  return () => {
    if (unsubscribeReferrals) {
      unsubscribeReferrals();
    }
    if (unsubscribeAssignedAgents) {
      unsubscribeAssignedAgents();
    }
  };
}, [navigate, auth, db]);
    



    
// Function to toggle individual quote collapse
const toggleQuoteCollapse = (quoteId) => {
  setCollapsedQuotes((prevState) => ({
    ...prevState,
    [quoteId]: !prevState[quoteId],
  }));
};

// Function to collapse all quotes
const collapseAllQuotes = () => {
  const allCollapsedState = {};
  assignedAgentsQuotes.forEach((quote) => {
    allCollapsedState[quote.id] = true;
  });
  setCollapsedQuotes(allCollapsedState);
};

// Function to uncollapse all quotes
const uncollapseAllQuotes = () => {
  const allUncollapsedState = {};
  assignedAgentsQuotes.forEach((quote) => {
    allUncollapsedState[quote.id] = false;
  });
  setCollapsedQuotes(allUncollapsedState);
};


  
  
    const fetchAssignedAgentsQuotes = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          throw new Error('User not authenticated');
        }
    
        const assignedAgentsQuery = query(
          collection(db, 'assignedAgents'),
          where('agentEmail', '==', user.email)
        );
        const assignedAgentsSnapshot = await getDocs(assignedAgentsQuery);
    
        const assignedAgentsList = await Promise.all(
          assignedAgentsSnapshot.docs.map(async assignedDoc => {
            const assignedQuoteData = assignedDoc.data();
    
            // Skip quotes that are archived
            if (assignedQuoteData.brokerArchived) {
              return null;
            }
    
            // Fetch the corresponding referral from `agentReferrals`
            const referralDocRef = doc(db, 'agentReferrals', assignedDoc.id);
            const referralDoc = await getDoc(referralDocRef);
    
            // Check if referral document exists
            const referralData = referralDoc.exists() ? referralDoc.data() : {};
            const referralStatus = referralData.quoteInfo?.referralStatus || 'Not Referred';
    
            return {
              id: assignedDoc.id,
              referralStatus,
              referredAgents: assignedQuoteData.referredAgents || [],
              referredCarriers: assignedQuoteData.referredCarriers || {},
              ...assignedQuoteData,
            };
          })
        );
    
        // Filter out null values in case any were archived
        return assignedAgentsList.filter(quote => quote !== null);
      } catch (error) {
        console.error('Error fetching assigned agents quotes:', error);
        return [];
      }
    };
    
    

      const handleReferOut = async (quote) => {
        setLoading(true);
        setSelectedQuote(quote); // Ensure selectedQuote is set
      
        try {
          // Fetch the current agent's data from michiganagentlist using their email
          const currentAgentQuery = query(collection(db, 'michiganagentlist'), where('email', '==', quote.agentEmail));
          const currentAgentSnapshot = await getDocs(currentAgentQuery);
      
          if (currentAgentSnapshot.empty) {
            throw new Error("Current agent's data not found in michiganagentlist.");
          }
      
          // Extract current agent's data and carrier codes
          const currentAgentData = currentAgentSnapshot.docs[0].data();
          const currentAgentCarrierCodes = currentAgentData.carrierList?.map(carrier => carrier.carrierCode).filter(Boolean) || [];
      
          // Set current agent including agencyName
          setCurrentAgent({
            ...currentAgentData,
            carrierList: currentAgentCarrierCodes,
          });
      
          // Fetch all agents in the same state as the current agent
          const agentsQuery = query(collection(db, 'michiganagentlist'), where('state', '==', currentAgentData.state));
          const agentsSnapshot = await getDocs(agentsQuery);
      
          // Process each agent document excluding the current agent
          const agentsList = agentsSnapshot.docs
            .map(doc => {
              const agentData = doc.data();
      
              // Check if agent has carrierList
              if (!agentData.carrierList || agentData.carrierList.length === 0) {
                return null;
              }
      
              // Extract carrier codes for each agent
              const agentCarrierCodes = agentData.carrierList.map(carrier => carrier.carrierCode).filter(Boolean);
      
              // Find different carriers between the current agent and each agent
              const differentCarriers = agentCarrierCodes.filter(code => !currentAgentCarrierCodes.includes(code));
      
              return { ...agentData, differentCarriers };
            })
            .filter(agent => agent && agent.email !== currentAgentData.email && agent.differentCarriers.length > 0);
      
          // Set the agents with different carriers and open the modal
          setModalAgents(agentsList);
      
          // Set selected agents (this would likely be set elsewhere in your code, adjust as needed)
          const selectedAgents = agentsList.map(agent => agent.agentId);
          setSelectedAgents(selectedAgents); // Ensure selectedAgents is set
      
          setIsModalOpen(true);
          
        } catch (err) {
          console.error('Error fetching agents:', err);
        } finally {
          setLoading(false);
        }
      };


      // Function to update referral details and open the modal automatically
  const handleUpdateReferralDetails = (details) => {
    setReferralDetails(details);
    setIsReferralDetailsModalOpen(true);
  };
  

  
 // Function to handle when a referral is successfully sent
 const handleReferralSent = async (quoteId) => {
  try {
    if (!quoteId) {
      throw new Error('Invalid quoteId: quoteId is undefined or null.');
    }

    // Re-fetch the updated assigned agents quotes
    const updatedQuotes = await fetchAssignedAgentsQuotes();

    // Filter out quotes that are archived before setting the state
    const filteredQuotes = updatedQuotes.filter(q => !q.brokerArchived);
    setAssignedAgentsQuotes(filteredQuotes);

    // Fetch the updated referral details for the specific quote
    const referralDoc = await getDoc(doc(db, 'agentReferrals', quoteId));
    if (referralDoc.exists()) {
      const referralDetails = referralDoc.data();
      handleUpdateReferralDetails(referralDetails);
    } else {
      console.error('No referral details found for quote:', quoteId);
    }
  } catch (error) {
    console.error('Error in handleReferralSent:', error);
  }
};



  
  
  const onSendReferral = (selectedAgents, selectedCarriers) => {
    const referredAgents = selectedAgents.map(agentId => {
      const agent = modalAgents.find(a => a.agentId === agentId);
      return {
        name: agent.name,
        email: agent.email,
        streetAddress: agent.streetAddress,
        city: agent.city,
        state: agent.state,
        zip: agent.zip,
        selectedCarriers: selectedCarriers[agentId] || [] // Ensure selectedCarriers is an array
      };
    });

    setAssignedAgentsQuotes(prevQuotes =>
      prevQuotes.map(q =>
        q.id === selectedQuote.id
          ? { ...q, referralStatus: 'referred out', referredAgents, referredCarriers: selectedCarriers }
          : q
      )
    );
    setIsModalOpen(false);
  };

  // Function to open the ReferralDetailsModal
  const openReferralDetailsModal = async (quote) => {
    try {
      const referralDoc = await getDoc(doc(db, 'agentReferrals', quote.id));
      if (referralDoc.exists()) {
        setReferralDetails(referralDoc.data());
      } else {
        setReferralDetails(null);
      }
      setIsReferralDetailsModalOpen(true);
    } catch (error) {
      console.error("Error fetching referral details:", error);
      setReferralDetails(null);
    }
  };
  
  const renderReferralStatus = (quote) => {
    return quote.referralStatus || 'Not Referred'; // Default to 'Not Referred' if no status is found
  };
  

    // Function to handle closing the ReferralDetailsModal
    const handleCloseReferralDetailsModal = () => {
      setIsReferralDetailsModalOpen(false);
    };

    const handleUploadClick = (quote) => {
      setSelectedQuote(quote);
      setIsAgentModalOpen(true); // Open the AgentFileUpload modal
    };

    const handleUploadComplete = () => {
      setIsAgentModalOpen(false);
    };

    

    const renderAgentAttachedFiles = (files) => {
      if (!files || files.length === 0) {
        return 'None';
      }



    
      return (
        <ul>
          {files.map((fileItem, index) => {
            if (typeof fileItem === 'string') {
              // If fileItem is a string, it's a URL
              const name = getFileNameFromURL(fileItem);
              return (
                <li key={index}>
                  <a href={fileItem} target="_blank" rel="noopener noreferrer">
                    {name || `File ${index + 1}`}
                  </a>
                </li>
              );
            } else {
              // fileItem is an object with 'url' and 'name'
              return (
                <li key={index}>
                  <a href={fileItem.url} target="_blank" rel="noopener noreferrer">
                    {fileItem.name || `File ${index + 1}`}
                  </a>
                </li>
              );
            }
          })}
        </ul>
      );
    };
    
    // Helper function to extract file name from URL
    const getFileNameFromURL = (url) => {
      try {
        const decodedURL = decodeURIComponent(url);
        const parts = decodedURL.split('/');
        const lastPart = parts[parts.length - 1];
        const [nameWithParams] = lastPart.split('?'); // Remove any query parameters
        return nameWithParams;
      } catch (error) {
        console.error('Error extracting file name from URL:', error);
        return 'Unknown File';
      }
    };
    


    const renderCloseDetails = (quote) => {
      if (quote.closeReason === 'pending') {
        return 'Pending';
      }
      if (quote.closeReason === 'successful') {
        return `Successful with ${quote.writtenCarrier}`;
      }
      if (quote.closeReason === 'unsuccessful') {
        return `Unsuccessful: ${quote.unsuccessfulReason}`;
      }
      return 'N/A';
    };

    const handleArchiveQuote = async (quote) => {
      console.log("Archive button clicked");
  
      if (!quote) {
          console.log("No quote found");
          return;
      }
  
      try {
          // Fetch the agent's data using the agentEmail from the selected quote
          const agentQuery = query(collection(db, 'michiganagentlist'), where('email', '==', quote.agentEmail));
          const agentSnapshot = await getDocs(agentQuery);
  
          if (!agentSnapshot.empty) {
              const agentData = agentSnapshot.docs[0].data();
              setAgentCarriers(agentData.carrierList); // Set the fetched carrier list to state
          } else {
              console.log("Agent not found in michiganagentlist");
          }
  
          setSelectedQuote(quote);
          setIsArchiveModalOpen(true);
          console.log("Selected Quote:", quote);
      } catch (error) {
          console.error("Error fetching agent's carriers:", error);
      }
  };
  
  
  const handleArchiveSubmit = async () => {
    console.log('Submitting archive');

    if (!selectedQuote) {
        console.log("No quote selected");
        return;
    }

    const updateData = {
        brokerArchived: true,
        archiveReason,
    };

    if (archiveReason === 'successful') {
        if (selectedCarrier === 'other' && otherCarrier) {
            updateData.writtenCarrier = otherCarrier;
        } else if (selectedCarrier !== 'referral') {
            updateData.writtenCarrier = selectedCarrier;
        }
    } else if (archiveReason === 'unsuccessful' && unsuccessfulReason) {
        updateData.unsuccessfulReason = unsuccessfulReason;
    }

    try {
        const quoteRef = doc(db, 'assignedAgents', selectedQuote.id);
        await updateDoc(quoteRef, updateData);
        console.log("Quote archived successfully:", updateData);

        setAssignedAgentsQuotes(prevQuotes =>
            prevQuotes.filter(q => q.id !== selectedQuote.id)
        );

        setIsArchiveModalOpen(false);
        setSelectedQuote(null);
    } catch (error) {
        console.error('Error archiving quote:', error);
    }
};

  


  const handleCloseArchiveModal = () => {
      setIsArchiveModalOpen(false);
      setSelectedQuote(null);
  };

  const filterQuotes = (quote) => {
    const search = filterText.toLowerCase();
    const timestamp = quote.timestamp?.toDate()?.toLocaleString()?.toLowerCase() || '';
    const formType = quote.formType?.toLowerCase() || '';
    const name = quote.name?.toLowerCase() || '';
    const email = quote.email?.toLowerCase() || '';
  
    return (
      timestamp.includes(search) ||
      formType.includes(search) ||
      name.includes(search) ||
      email.includes(search)
    );
  };

  
  
  const sortedQuotes = assignedAgentsQuotes
  .filter((quote) => quote.timestamp) // Make sure quote has a timestamp
  .sort((a, b) => b.timestamp?.toDate() - a.timestamp?.toDate()); // Sort in descending order by timestamp

  const filteredSortedQuotes = assignedAgentsQuotes
  .filter((quote) => !quote.brokerArchived)
  .filter(filterQuotes)
  .sort((a, b) => b.timestamp?.toDate() - a.timestamp?.toDate());

    return (
      <div className='broker-dash-assigned'>
        <div 
          className="section-header" 
          onClick={() => setIsSectionCollapsed(prev => !prev)} 
          aria-label={isSectionCollapsed ? 'Expand Lender Leads' : 'Collapse Lender Leads'}
          style={{ cursor: 'pointer' }}        
        >
  <span className="section-toggle-symbol">
    {isSectionCollapsed ? '+' : '-'}
  </span>
        <h2 className='broker-dash-title'>Assigned Agents Quotes</h2>
        {isSectionCollapsed && (
          <p className="item-count">
            ({filteredSortedQuotes.length} items)
          </p>
        )}
      </div>
            {/* Render content conditionally based on isSectionCollapsed */}
            {!isSectionCollapsed && (
        <>
          {/* Collapse Controls */}
          <div className="collapse-controls">
            <button onClick={collapseAllQuotes} className="collapse-all-button">
              Collapse All
            </button>
            <button onClick={uncollapseAllQuotes} className="uncollapse-all-button">
              Uncollapse All
            </button>
          </div>
        <div className='filter-container'>
        <input
  type="text"
  placeholder="Search by Timestamp, Form Type, Name, or Email"
  value={filterText}
  onChange={(e) => setFilterText(e.target.value)}
  className="filter-input"
/>
</div>


{/* Leads List */}
{filteredSortedQuotes.length > 0 ? (
            <ul className="leads-list">
              {filteredSortedQuotes.map(quote => (
                <li key={quote.id} className="lead-item"
                onClick={() => toggleQuoteCollapse(quote.id)} // Make the entire quote item clickable
                style={{ cursor: 'pointer' }}
                >
                  {/* Quote Header */}
                  <div className="quote-header">
                  <span className="toggle-symbol">
      {collapsedQuotes[quote.id] ? '+' : '-'}
    </span>
                    {/* Collapsed Info */}
                    <div className="collapsed-info">
                      <p>
                        <strong>Timestamp:</strong> {quote.timestamp?.toDate().toLocaleString()}
                      </p>
                      <p>
                        <strong>Name:</strong> {quote.name || 'N/A'}
                      </p>
                      <p>
                        <strong>Email:</strong> {quote.email || 'N/A'}
                      </p>
                    </div>
                  </div>





         {/* Full Details */}
         {!collapsedQuotes[quote.id] && (
                    <>
                      <div className="quote-details">
                        <p><strong>Agent Email:</strong> {quote.agentEmail}</p>
                        <p><strong>Form Type:</strong> {quote.formType}</p>
                        <p><strong>Address:</strong> {quote.address ? `${quote.address.streetAddress || ''}, ${quote.address.city || ''}, ${quote.address.state || ''}, ${quote.address.zipCode || ''}` : 'N/A'}</p>
                        <p><strong>Birthdate:</strong> {quote.birthdate}</p>
                        <p><strong>Phone:</strong> {quote.phone}</p>
                        <p><strong>Additional Notes:</strong> {quote.additionalNotes}</p>
                        <p><strong>Attached Files:</strong> {quote.attachedFiles ? quote.attachedFiles.map((file, index) => (
                          <a key={index} href={file} target="_blank" rel="noopener noreferrer">{`File ${index + 1}`}</a>
                        )) : 'None'}</p>
                        <p>
                          <strong>Agent Attached Files:</strong>
                          {renderAgentAttachedFiles(quote.agentAttachedFiles)}
                        </p>
                        <p><strong>Referral Status:</strong> {renderReferralStatus(quote)}</p>
                        <p><strong>Close Reason:</strong> {renderCloseDetails(quote)}</p>
                      </div>
                      <div className="lead-actions">
                      <button
    className="action-button"
    onClick={(e) => {
      e.stopPropagation(); // Prevent collapsing on button click
      handleReferOut(quote);
    }}
  >                          Refer Out</button>
  <button
    className="action-button"
    onClick={(e) => {
      e.stopPropagation();
      openReferralDetailsModal(quote);
    }}
  >                          Referral Details</button>
  <button
    className="action-button"
    onClick={(e) => {
      e.stopPropagation();
      handleUploadClick(quote);
    }}
  >                          Upload Files</button>
  <button
    className="action-button"
    onClick={(e) => {
      e.stopPropagation();
      handleArchiveQuote(quote);
    }}
  >                          
  Archive</button>
                      </div>
                    </>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p className='no-leads-message'>No assigned agents quotes found.</p>
          )}

        <ReferOutModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          agents={modalAgents}
          currentAgent={currentAgent}
          selectedQuote={selectedQuote}
          onSendReferral={handleReferralSent}
          setIsModalOpen={setIsModalOpen}
          onUpdateReferralDetails={handleUpdateReferralDetails}
        />

        {isReferralDetailsModalOpen && (
          <ReferralDetailsModal
            isOpen={isReferralDetailsModalOpen}
            onClose={() => setIsReferralDetailsModalOpen(false)}
            referralDetails={referralDetails}
          />
        )}

        {isAgentModalOpen && (
          <AgentFileUpload
  quote={selectedQuote}
  onUploadComplete={() => setIsAgentModalOpen(false)}
  onClose={() => setIsAgentModalOpen(false)}
  collectionName="assignedAgents" // Pass assignedAgents as the collection
/>
        )}
      
      {isArchiveModalOpen && (
   <div className="broker-dash-assigned-archive-modal">
      <h3 className="broker-dash-assigned-archive-modal-title">Archive Quote</h3>
      <label className="broker-dash-assigned-archive-modal-label">
         Archive Reason:
         <select
            className="broker-dash-assigned-archive-modal-select"
            value={archiveReason}
            onChange={(e) => setArchiveReason(e.target.value)}
         >
            <option value="">Select a reason</option>
            <option value="successful">Archive Successful</option>
            <option value="unsuccessful">Archive Unsuccessful</option>
         </select>
      </label>

      {archiveReason === 'successful' && (
    <div>
        <label className="broker-dash-assigned-archive-modal-label">
            Select Option:
            <select
                className="broker-dash-assigned-archive-modal-select"
                value={selectedCarrier}
                onChange={(e) => setSelectedCarrier(e.target.value)}
            >
                <option value="">Select an option</option>
                <option value="referral">Referral</option>
                {agentCarriers.length > 0 && agentCarriers.map((carrier, index) => (
                    <option key={index} value={carrier.carrierCode}>{carrier.carrierName}</option>
                ))}
                <option value="other">Other</option>
            </select>
        </label>

        {selectedCarrier === 'other' && (
            <input
                type="text"
                className="broker-dash-assigned-archive-modal-input"
                placeholder="Enter other carrier"
                value={otherCarrier} // Add `otherCarrier` state to handle this input
                onChange={(e) => setOtherCarrier(e.target.value)}
            />
        )}
    </div>
)}




      {archiveReason === 'unsuccessful' && (
         <label className="broker-dash-assigned-archive-modal-label">
            Unsuccessful Reason:
            <select
               className="broker-dash-assigned-archive-modal-select"
               value={unsuccessfulReason}
               onChange={(e) => setUnsuccessfulReason(e.target.value)}
            >
               <option value="">Select a reason</option>
               <option value="could_not_reach">Couldn't reach</option>
               <option value="not_valid">Not valid</option>
               <option value="not_competitive">Not competitive</option>
            </select>
         </label>
      )}

      <button
         className="broker-dash-assigned-archive-modal-submit"
         onClick={handleArchiveSubmit}
      >
         Submit
      </button>
      <button
         className="broker-dash-assigned-archive-modal-cancel"
         onClick={handleCloseArchiveModal}
      >
         Cancel
      </button>
   </div> 
    )}
    </>
  )}
</div>
);
};

export default BrokerDashAssigned;