import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Spinner from '../../Spinner';
import './referOutModal.css';

Modal.setAppElement('#root');

const ReferOutModal = ({
  isOpen,
  onClose,
  agents,
  currentAgent,
  selectedQuote,
  onSendReferral,
  setIsModalOpen,
}) => {
  const [selectedCarriers, setSelectedCarriers] = useState({});
  const [loading, setLoading] = useState(false); // Add loading state
  const [filterText, setFilterText] = useState('');



  useEffect(() => {
    if (isOpen) {
      setSelectedCarriers({});
    }
  }, [isOpen]);

  const handleSelectCarrier = (agentId, carrierCode) => {
    agentId = String(agentId).trim(); // Ensure agentId is a trimmed string
    setSelectedCarriers((prevSelected) => {
      const newSelected = {
        ...prevSelected,
        [agentId]: prevSelected[agentId]?.includes(carrierCode)
          ? prevSelected[agentId].filter((code) => code !== carrierCode)
          : [...(prevSelected[agentId] || []), carrierCode],
      };
      console.log('Updated selectedCarriers:', newSelected);
      return newSelected;
    });
  };

  const handleSendReferral = async (agentId) => {
    agentId = String(agentId).trim(); // Ensure agentId is a trimmed string
  
    // Check if at least one carrier is selected for the agent
    if (!selectedCarriers[agentId] || selectedCarriers[agentId].length === 0) {
      alert('Please select at least one carrier for this agent before sending the referral.');
      return;
    }
  
    const functions = getFunctions();
    const sendReferral = httpsCallable(functions, 'sendReferral');
  
    try {
      setLoading(true); // Start spinner before sending referral
  
      const {
        agentId: currentAgentId,
        name,
        email,
        streetAddress,
        city,
        state,
        zip,
        carrierList,
        agencyName,
      } = currentAgent;
  
      // Include all necessary fields from selectedQuote
      const quoteInfo = {
        ...selectedQuote,
        referralStatus: 'Referral Sent',
      };
  
      const payload = {
        currentAgent: {
          agentId: currentAgentId,
          name,
          email,
          streetAddress,
          city,
          state,
          zip,
          carrierList,
          agencyName,
        },
        selectedAgents: [agentId],
        selectedCarriers: {
          [agentId]: selectedCarriers[agentId],
        },
        quoteInfo,
      };
  
      console.log('Payload being sent to Cloud Function:', payload);
  
      await sendReferral(payload);
  
      // Update the UI after a successful referral
      onSendReferral(selectedQuote.id);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error sending referral:', error);
      alert('An error occurred while sending the referral. Please try again.');
    } finally {
      setLoading(false); // Stop spinner after the referral is sent (success or failure)
    }
  };

  const filterAgents = (agent) => {
    const search = filterText.toLowerCase();
  
    // Check agent's name
    const name = agent.name?.toLowerCase() || '';
    if (name.includes(search)) return true;
  
    // Check agent's email
    const email = agent.email?.toLowerCase() || '';
    if (email.includes(search)) return true;
  
    // Check agent's zip code
    const zip = agent.zip?.toLowerCase() || '';
    if (zip.includes(search)) return true;
  
    // Check agent's carrier codes
    const carrierCodes = agent.differentCarriers?.map(code => code.toLowerCase()) || [];
    if (carrierCodes.some(code => code.includes(search))) return true;
  
    return false;
  };
  
  

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Refer Out Agents"
    className="ReactModal__Content"
    overlayClassName="ReactModal__Overlay"
    >
      <h2>Refer Out Agents</h2>

      {/* Filter Input */}
<input
  type="text"
  placeholder="Search agents by name, email, zip, or carrier code"
  value={filterText}
  onChange={(e) => setFilterText(e.target.value)}
  className="filter-input"
/>

      {/* Show the spinner if loading */}
      {loading && (
        <div className="spinner-overlay">
          <Spinner />
        </div>
      )}


{agents
        .filter(filterAgents)
        .map((agent) => {
          const agentIdStr = String(agent.agentId || agent.id).trim();
          return (
            <div key={agentIdStr} className="agent-card">
              <h3>{agent.name}</h3>
              <p>Email: {agent.email}</p>
              <p>
                Address: {agent.streetAddress}, {agent.city}, {agent.state}, {agent.zip}
              </p>
              <p>Available Carriers:</p>
              <div className="carrier-list">
                {agent.differentCarriers && agent.differentCarriers.length > 0 ? (
                  agent.differentCarriers.map((carrierCode) => (
                    <label key={carrierCode} className="carrier-label">
                      <input
                        type="checkbox"
                        checked={
                          selectedCarriers[agentIdStr]?.includes(carrierCode) || false
                        }
                        onChange={() => handleSelectCarrier(agentIdStr, carrierCode)}
                      />
                      {carrierCode}
                    </label>
                  ))
                ) : (
                  <p>No different carriers available for this agent.</p>
                )}
              </div>
              <button
                onClick={() => handleSendReferral(agentIdStr)}
                disabled={loading}
                className="modal-button send-button"
              >
                Send Referral
              </button>
            </div>
          );
        })}
      <button onClick={onClose} className='modal-button close-button'>Close</button>
    </Modal>
  );
};

export default ReferOutModal;


