import React, { useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';

const AssignUserRoles = () => {
  const [uid, setUid] = useState(''); // Consider renaming this to agentId for clarity
  const [brokerLogin, setBrokerLogin] = useState(false);
  const [anotherRole, setAnotherRole] = useState(false);
  const [temporaryPassword, setTemporaryPassword] = useState(true); // Default to true
  const [message, setMessage] = useState('');

  const auth = getAuth();
  const functions = getFunctions();
  const setUserRoles = httpsCallable(functions, 'setUserRoles');

  const handleAssignRoles = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        console.error('No user is currently logged in');
        return;
      }

      const idTokenResult = await user.getIdTokenResult(true);
      if (!idTokenResult.claims.admin) {
        console.error('User does not have admin privileges');
        return;
      }

      // Prepare the data to send, including temporaryPassword as a separate field
      const data = {
        uid,
        roles: {
          brokerLogin,
          anotherRole,
        },
        temporaryPassword, // Include temporaryPassword as a separate field
      };

      const result = await setUserRoles(data);
      setMessage(result.data.message);
    } catch (error) {
      console.error('Error setting user roles:', error);
      setMessage('Error setting user roles');
    }
  };

  return (
    <div>
      <h2>Assign User Roles</h2>
      <input
        type="text"
        placeholder="User ID"
        value={uid}
        onChange={(e) => setUid(e.target.value)}
      />
      <label>
        <input
          type="checkbox"
          checked={brokerLogin}
          onChange={(e) => setBrokerLogin(e.target.checked)}
        />
        Broker Login
      </label>
      <button onClick={handleAssignRoles}>Assign Roles</button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default AssignUserRoles;


